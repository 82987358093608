import { FormType, SocialPlatform } from '../../../../../types/FormTypes';
import { NextImageBasicData } from '../../../../../types/Next';

export const emailDefaultToneOptions: string[] = [
  '🤵 Formal',
  '😎 Informal',
  '🤞 Optimistic',
  '🤣 Funny',
  '😊 Friendly',
  '😄 Enthusiastic',
  '💪 Assertive',
  '👍 Encouraging',
  '😏 Sassy',
  '😑 Serious',
  // '🪨 The Rock',
];

export const blogDefaultToneOptions: string[] = emailDefaultToneOptions;

export const socialDefaultToneOptions: string[] = emailDefaultToneOptions;

export const suggestedTones = {
  [FormType.SOCIAL]: {
    [SocialPlatform.FACEBOOK]: 'Facebook suggested tone',
    [SocialPlatform.INSTAGRAM]: 'Instagram suggested tone',
    [SocialPlatform.TWITTER]: 'Twitter suggested tone',
    [SocialPlatform.LINKEDIN]: 'LinkedIn suggested tone',
  },
};

export const socialDefaultPlatformOptions: string[] = [
  SocialPlatform.FACEBOOK,
  SocialPlatform.INSTAGRAM,
  SocialPlatform.TWITTER,
  SocialPlatform.LINKEDIN,
];

export const socialDefaultPlatformIcons: {
  [key in SocialPlatform]: NextImageBasicData;
} = {
  [SocialPlatform.FACEBOOK]: {
    src: '/socials/FacebookBlack.svg',
    height: 16,
    width: 16,
  },
  [SocialPlatform.INSTAGRAM]: {
    src: '/socials/InstagramBlack.svg',
    height: 16,
    width: 16,
  },
  [SocialPlatform.TWITTER]: {
    src: '/socials/TwitterBlack.svg',
    height: 16,
    width: 16,
  },
  [SocialPlatform.LINKEDIN]: {
    src: '/socials/LinkedInBlack.svg',
    height: 16,
    width: 16,
  },
};

export const defaultDemographics = [
  'Gen Z',
  'Millenial',
  'Gen X',
  'Baby Boomer',
  'Urban',
  'College Graduate',
  'Student',
  'Writer',
  'Artist',
];

export const suggestedDemographics = {
  [FormType.SOCIAL]: {
    [SocialPlatform.FACEBOOK]: 'Facebook suggested demographic',
    [SocialPlatform.INSTAGRAM]: 'Instagram suggested demographic',
    [SocialPlatform.TWITTER]: 'Twitter suggested demographic',
    [SocialPlatform.LINKEDIN]: 'LinkedIn suggested demographic',
  },
};

export const industryOptions: string [] = [
  'Advertising and Marketing',
  'Aerospace',
  'Agriculture',
  'Computer & Technology',
  'Construction',
  'Education',
  'Energy',
  'Entertainment',
  'Fashion',
  'Finance and Economics',
  'Food and Beverage',
  'Healthcare',
  'Hospitality',
  'Manufacturing',
  'Media and News',
  'Mining',
  'Pharmaceutical',
  'Telecommunication',
  'Transportation',
];

export const ageOptions: string[] = [
  '13-17',
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65+',
];

export const locationOptions: string[] = [
  'City',
  'Rural',
  'Suburbs',
  'Atlanta, GA',
  'San Francisco, CA',
  'Chicago, IL',
  'United States',
  'Korea',
  'Europe',
];

export const genderOptions: string[] = [
  'Both',
  'Female',
  'Male',
  'Other',
];

export const occupationOptions: string[] =[
  'Pet Owner',
  'Teacher',
  'College Student',
  'Early Adopter',
  'Marketer',
  'Small Business Owner',
  'Server',
  'Mechanic',
  'Single',
  'Parent',
  'Enjoys Cruises',
];