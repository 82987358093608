import { FC } from 'react';

type TwitterSvgProps = {
  stroke?: string;
};

export const TwitterSvg: FC<TwitterSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_224_3979)">
<path d="M4.40426 12.6876C9.68598 12.6876 12.5757 8.31064 12.5757 4.51615C12.5757 4.3931 12.5729 4.26732 12.5675 4.14427C13.1296 3.73775 13.6147 3.23421 14 2.65732C13.4765 2.89024 12.9206 3.04237 12.3514 3.10849C12.9507 2.74926 13.3995 2.18492 13.6145 1.52009C13.0507 1.85422 12.4341 2.08992 11.7912 2.21708C11.358 1.7568 10.7852 1.45205 10.1615 1.34992C9.53772 1.2478 8.89769 1.35401 8.34035 1.65212C7.783 1.95023 7.33938 2.42364 7.07808 2.99916C6.81677 3.57468 6.75233 4.22025 6.89473 4.83607C5.75312 4.77878 4.6363 4.48222 3.61666 3.96562C2.59702 3.44902 1.69732 2.7239 0.975898 1.83728C0.609233 2.46945 0.497033 3.21752 0.662101 3.92945C0.82717 4.64137 1.25712 5.26374 1.86457 5.67005C1.40853 5.65557 0.962486 5.53279 0.563281 5.31185V5.3474C0.562873 6.01082 0.792224 6.6539 1.21235 7.16734C1.63247 7.68078 2.21744 8.03289 2.86781 8.1638C2.44537 8.27939 2.00199 8.29623 1.57199 8.21302C1.75552 8.78357 2.11258 9.28258 2.59337 9.64042C3.07415 9.99826 3.65465 10.1971 4.25387 10.2091C3.23658 11.0082 1.97993 11.4416 0.686328 11.4396C0.456917 11.4392 0.227733 11.4252 0 11.3975C1.31417 12.2406 2.84289 12.6884 4.40426 12.6876Z" fill={stroke}/>
</g>
<defs>
<clipPath id="clip0_224_3979">
<rect width="14" height="14" fill={stroke}/>
</clipPath>
</defs>
</svg>
  );
};
