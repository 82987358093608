/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { APIErrors } from '../types/API';
import {
  MarketingIdeasIntents,
  ContentImproverIntents,
} from '../types/FormOptions.type';
import {
  AudienceRetargetInput,
  BlogInput,
  MarketingIdeasInput,
  ContentImproverInput,
  EmailInput,
  FormType,
  GeneratedResponse,
  InputLength,
  OutputQuantity,
  SocialInput,
  PersonaSocialInput,
  SocialPlatform,
  HeroHeaderInput,
  AllWebsiteInputs,
  SubheaderInput,
  AudienceChangerInput,
  FormatChangerInput,
  ToneChangerInput,
  FeatureDescriptionInput,
  CallToActionInput,
  PersonaMarketingIdeasInput,
  PersonaBlogInput,
  PersonaEmailInput,
  PersonaCallToActionInput,
  PersonaFeatureDescriptionInput,
  PersonaHeroHeaderInput,
  PersonaSubheaderInput,
  PersonaNewsletterInput,
  PASFrameworkInput,
  SeasonalHolidayInput,
  DiscountPromotionInput,
  GiveAwayPromotionInput,
  LinkedInCompanyBioInput,
  YoutubeChannelDescriptionInput,
  TikTokCaptionInput,
  ProductDescriptionInput,
  MetaAdsInput,
  GoogleAdsInput,
  ValuePropInput,
  YoutubeVideoDescriptionInput,
  BrandMissionInput,
  YoutubeVideoScriptInput,
  ShortVideoScriptInput,
  JobDescriptionInput,
  ShortVideoLength,
  LinkedInDirectMessageInput,
} from '../types/FormTypes';
import { useAuthContext } from './AuthContext';
import { usePersonaContext } from './PersonaContext';

type FormSubmissionInputs<T> = {
  values: T;
  formType: FormType;
  actions?: FormikHelpers<T>;
  keepPreviousResponses?: boolean;
};

type GeneratedFormat = {
  formType: FormType;
  platform?: SocialPlatform;
};

interface FormContextInterface {
  formType: FormType;
  setFormType: Dispatch<SetStateAction<FormType>>;
  blogInput: BlogInput | undefined;
  setBlogInput: Dispatch<SetStateAction<BlogInput | undefined>>;
  personaBlogInput: PersonaBlogInput | undefined;
  setPersonaBlogInput: Dispatch<SetStateAction<PersonaBlogInput | undefined>>;
  emailInput: EmailInput | undefined;
  setEmailInput: Dispatch<SetStateAction<EmailInput | undefined>>;
  personaEmailInput: PersonaEmailInput | undefined;
  setPersonaEmailInput: Dispatch<SetStateAction<PersonaEmailInput | undefined>>;
  socialInput: SocialInput | undefined;
  setSocialInput: Dispatch<SetStateAction<SocialInput | undefined>>;
  personaSocialInput: PersonaSocialInput | undefined;
  setPersonaSocialInput: Dispatch<
    SetStateAction<PersonaSocialInput | undefined>
  >;
  tiktokCaptionInput: TikTokCaptionInput | undefined;
  setTikTokCaptionInput: Dispatch<
    SetStateAction<TikTokCaptionInput | undefined>
  >;

  marketingIdeasInput: MarketingIdeasInput;
  setMarketingIdeasInput: Dispatch<SetStateAction<MarketingIdeasInput>>;
  personaMarketingIdeasInput: PersonaMarketingIdeasInput;
  setPersonaMarketingIdeasInput: Dispatch<
    SetStateAction<PersonaMarketingIdeasInput>
  >;

  contentImproverInput: ContentImproverInput;
  setContentImproverInput: Dispatch<SetStateAction<ContentImproverInput>>;

  audienceRetargetInput: AudienceRetargetInput;
  setAudienceRetargetInput: Dispatch<SetStateAction<AudienceRetargetInput>>;

  heroHeaderInput: HeroHeaderInput;
  setHeroHeaderInput: Dispatch<SetStateAction<HeroHeaderInput>>;
  personaHeroHeaderInput: PersonaHeroHeaderInput;
  setPersonaHeroHeaderInput: Dispatch<SetStateAction<PersonaHeroHeaderInput>>;
  subheaderInput: SubheaderInput;
  setSubheaderInput: Dispatch<SetStateAction<SubheaderInput>>;
  personaSubheaderInput: PersonaSubheaderInput;
  setPersonaSubheaderInput: Dispatch<SetStateAction<PersonaSubheaderInput>>;
  featureDescriptionInput: FeatureDescriptionInput;
  setFeatureDescriptionInput: Dispatch<SetStateAction<FeatureDescriptionInput>>;
  personaFeatureDescriptionInput: PersonaFeatureDescriptionInput;
  setPersonaFeatureDescriptionInput: Dispatch<
    SetStateAction<PersonaFeatureDescriptionInput>
  >;
  callToActionInput: CallToActionInput;
  setCallToActionInput: Dispatch<SetStateAction<CallToActionInput>>;
  personaCallToActionInput: PersonaCallToActionInput;
  setPersonaCallToActionInput: Dispatch<
    SetStateAction<PersonaCallToActionInput>
  >;

  personaNewsletterInput: PersonaNewsletterInput;
  setPersonaNewsletterInput: Dispatch<SetStateAction<PersonaNewsletterInput>>;

  audienceChangerInput: AudienceChangerInput;
  setAudienceChangerInput: Dispatch<SetStateAction<AudienceChangerInput>>;
  formatChangerInput: FormatChangerInput;
  setFormatChangerInput: Dispatch<SetStateAction<FormatChangerInput>>;
  toneChangerInput: ToneChangerInput;
  setToneChangerInput: Dispatch<SetStateAction<ToneChangerInput>>;

  pasFrameworkInput: PASFrameworkInput;
  setPASFrameworkInput: Dispatch<SetStateAction<PASFrameworkInput>>;

  seasonalHolidayInput: SeasonalHolidayInput;
  setSeasonalHolidayInput: Dispatch<SetStateAction<SeasonalHolidayInput>>;
  discountPromotionInput: DiscountPromotionInput;
  setDiscountPromotionInput: Dispatch<SetStateAction<DiscountPromotionInput>>;
  giveawayPromotionInput: GiveAwayPromotionInput;
  setGiveAwayPromotionInput: Dispatch<SetStateAction<GiveAwayPromotionInput>>;

  linkedinCompanyBioInput: LinkedInCompanyBioInput;
  setLinkedInCompanyBioInput: Dispatch<SetStateAction<LinkedInCompanyBioInput>>;
  linkedinDirectMessageInput: LinkedInDirectMessageInput;
  setLinkedInDirectMessageInput: Dispatch<
    SetStateAction<LinkedInDirectMessageInput>
  >;

  youtubeChannelDescriptionInput: YoutubeChannelDescriptionInput;
  setYoutubeChannelDescriptionInput: Dispatch<
    SetStateAction<YoutubeChannelDescriptionInput>
  >;
  youtubeVideoDescriptionInput: YoutubeVideoDescriptionInput;
  setYoutubeVideoDescriptionInput: Dispatch<
    SetStateAction<YoutubeVideoDescriptionInput>
  >;
  youtubeVideoScriptInput: YoutubeVideoScriptInput;
  setYoutubeVideoScriptInput: Dispatch<SetStateAction<YoutubeVideoScriptInput>>;
  shortVideoScriptInput: ShortVideoScriptInput;
  setShortVideoScriptInput: Dispatch<SetStateAction<ShortVideoScriptInput>>;

  jobDescriptionInput: JobDescriptionInput;
  setJobDescriptionInput: Dispatch<SetStateAction<JobDescriptionInput>>;

  productDescriptionInput: ProductDescriptionInput;
  setProductDescriptionInput: Dispatch<SetStateAction<ProductDescriptionInput>>;
  metaAdsInput: MetaAdsInput;
  setMetaAdsInput: Dispatch<SetStateAction<MetaAdsInput>>;
  googleAdsInput: GoogleAdsInput;
  setGoogleAdsInput: Dispatch<SetStateAction<GoogleAdsInput>>;

  brandMissionInput: BrandMissionInput;
  setBrandMissionInput: Dispatch<SetStateAction<BrandMissionInput>>;

  valuePropInput: ValuePropInput;
  setValuePropInput: Dispatch<SetStateAction<ValuePropInput>>;

  showAdvancedInputs: boolean;
  setShowAdvancedInputs: Dispatch<SetStateAction<boolean>>;

  generatedOutput: GeneratedResponse[];
  setGeneratedOutput: Dispatch<SetStateAction<GeneratedResponse[]>>;
  editedOutput: GeneratedResponse[];
  setEditedOutput: Dispatch<SetStateAction<GeneratedResponse[]>>;
  canKeepGoing: boolean;
  setCanKeepGoing: Dispatch<SetStateAction<boolean>>;

  lastGeneratedFormat: GeneratedFormat | undefined;

  submitContentForm: <
    T extends
      | BlogInput
      | PersonaBlogInput
      | EmailInput
      | PersonaEmailInput
      | SocialInput
      | PersonaSocialInput
      | TikTokCaptionInput
      | PersonaNewsletterInput
      | SeasonalHolidayInput
      | DiscountPromotionInput
      | GiveAwayPromotionInput
      | LinkedInCompanyBioInput
      | LinkedInDirectMessageInput
      | YoutubeChannelDescriptionInput
      | YoutubeVideoDescriptionInput
      | YoutubeVideoScriptInput
      | ShortVideoScriptInput
      | JobDescriptionInput
      | MetaAdsInput
      | GoogleAdsInput
  >({
    values,
    formType,
    actions,
    keepPreviousResponses,
  }: FormSubmissionInputs<T>) => Promise<void>;
  submitBrainstormForm: <
    T extends MarketingIdeasInput | PersonaMarketingIdeasInput
  >({
    values,
    formType,
    actions,
  }: FormSubmissionInputs<T>) => Promise<void>;
  submitToolkitForm: <
    T extends
      | ContentImproverInput
      | AudienceRetargetInput
      | AudienceChangerInput
      | FormatChangerInput
      | ToneChangerInput
      | PASFrameworkInput
      | ProductDescriptionInput
      | ValuePropInput
      | BrandMissionInput
  >({
    values,
    formType,
    actions,
  }: FormSubmissionInputs<T>) => Promise<void>;
  submitWebsiteForm: <T extends AllWebsiteInputs>({
    values,
    formType,
    actions,
    keepPreviousResponses,
  }: FormSubmissionInputs<T>) => Promise<void>;

  updateWords: (words: string) => Promise<void>;

  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isStateSetByJumpBackIn: boolean;
  setIsStateSetByJumpBackIn: Dispatch<SetStateAction<boolean>>;
  jumpBackInType: string;
  setJumpBackInType: Dispatch<SetStateAction<string>>;
  jumpBackInValues: any;
  setJumpBackInValues: Dispatch<SetStateAction<any>>;
}

const FormContext = createContext<FormContextInterface | undefined>(undefined);

export const FormContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { userData } = useAuthContext();
  const { currentBrandPersona, currentAudPersona } = usePersonaContext();

  const defaultBlogInput: BlogInput = {
    companyName: '',
    companyWebsite: '',
    intent: '',
    topics: [],
    tone: currentBrandPersona?.tone ?? [],
    toneDegree: {},
    targetDemographic: [],
    length: InputLength.MEDIUM,
    temperature: '90',
    emoji: 'Disabled',
    hashtag: 'Disabled',
  };

  const defaultPersonaBlogInput: PersonaBlogInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    website: currentBrandPersona?.website ?? '',
    intent: '',
    age: currentAudPersona?.age ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    location: currentAudPersona?.location ?? [],
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    industry: currentBrandPersona?.industry ?? '',
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    topics: [],
    tone: currentBrandPersona?.tone ?? [],
    toneDegree: {},
    length: InputLength.MEDIUM,
    temperature: '90',
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultEmailInput: EmailInput = {
    ...defaultBlogInput,
    multipleOutputs: OutputQuantity['SINGLE'],
    tone: [],
    targetDemographic: [],
  };

  const defaultPersonaEmailInput: PersonaEmailInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    intent: '',
    recipientPersonalDetails: '',
    previousUnansweredEmail: '',
    previousEngagementDetails: '',
    specialOffer: '',
    emailContentDescription: '',
    emailType: '',
    product: userData?.recentProduct ?? '',
    topics: [],
    productDescription: userData?.recentProductDescription ?? '',
    length: InputLength.MEDIUM,
    temperature: '90',
    multipleOutputs: OutputQuantity['SINGLE'],
    tone: currentBrandPersona?.tone ?? [],
    toneDegree: {},
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultSocialInput: SocialInput = {
    ...defaultBlogInput,
    platform: SocialPlatform.TWITTER,
    multipleOutputs: OutputQuantity['MULTIPLE'],
    tone: ['Twitter suggested tone'],
    targetDemographic: ['Twitter suggested demographic'],
    emoji: 'Enabled',
    hashtag: 'Disabled',
  };

  const defaultPersonaSocialInput: PersonaSocialInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    intent: '',
    topics: [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    length: InputLength.MEDIUM,
    temperature: '90',
    platform: SocialPlatform.TWITTER,
    multipleOutputs: OutputQuantity['MULTIPLE'],
    tone: ['Twitter suggested tone'],
    toneDegree: {},
    emoji: 'Enabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultTikTokCaptionInput: TikTokCaptionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    intent: '',
    topics: [],
    videoDescription: '',
    length: InputLength.MEDIUM,
    temperature: '90',
    multipleOutputs: OutputQuantity['MULTIPLE'],
    tone: ['TikTok suggested tone'],
    toneDegree: {},
    emoji: 'Enabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultMarketingIdeasInput: MarketingIdeasInput = {
    // MarketingIdeasIntents is a mapping from <frontend value, backend value>
    intent: Object.keys(MarketingIdeasIntents)[0],
    companyName: '',
    companyWebsite: '',
  };

  const defaultPersonaMarketingIdeasInput: PersonaMarketingIdeasInput = {
    // MarketingIdeasIntents is a mapping from <frontend value, backend value>
    intent: '',
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    industry: currentBrandPersona?.industry ?? '',
    age: currentAudPersona?.age ?? [],
    topic: '',
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    location: currentAudPersona?.location ?? [],
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultContentImproverInput: ContentImproverInput = {
    // ContentImproverIntents is a mapping from <frontend value, backend value>
    intent: Object.keys(ContentImproverIntents)[0],
    originalCopy: '',
  };

  const defaultAudienceRetargetInput: AudienceRetargetInput = {
    // ContentImproverIntents is a mapping from <frontend value, backend value>
    targetDemographic: [],
    originalCopy: '',
  };

  const defaultHeroHeader: HeroHeaderInput = {
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    targetDemographic: [],
  };

  const defaultPersonaHeroHeader: PersonaHeroHeaderInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultFeatureDescriptionInput: FeatureDescriptionInput = {
    featureName: '',
    tone: currentBrandPersona?.tone ?? [],
    targetDemographic: [],
  };

  const defaultPersonaFeatureDescriptionInput: PersonaFeatureDescriptionInput =
    {
      featureName: '',
      brand: currentBrandPersona?.brand ?? '',
      brandPositioning: currentBrandPersona?.brandPositioning ?? '',
      brandDescription: currentBrandPersona?.description ?? '',
      industry: currentBrandPersona?.industry ?? '',
      website: currentBrandPersona?.website ?? '',
      tone: currentBrandPersona?.tone ?? [],
      painpoint: currentAudPersona?.painpoints ?? '',
      goals: currentAudPersona?.goals ?? '',
      barriers: currentAudPersona?.barriers ?? '',
      triggers: currentAudPersona?.triggers ?? '',
      location: currentAudPersona?.location ?? [],
      occupation: currentAudPersona?.occupation ?? [],
      audience: currentAudPersona?.audience ?? '',
      brandPic: currentBrandPersona?.profilePic ?? '',
      audPic: currentAudPersona?.profilePic ?? '',
      age: currentAudPersona?.age ?? [],
      toneDegree: {},
      temperature: '90',
      brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
      brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
        { replaceThis: '', withThis: '' },
      ],
      brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
    };

  const defaultPersonaSubheaderInput: PersonaSubheaderInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultPersonaCallToActionInput: PersonaCallToActionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultPersonaNewsletterInput: PersonaNewsletterInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    topics: [],
    intent: '',
    lengthInput: 'Short',
    newsletterType: '(Please select an option)',
    newsletterTopics: '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    toneDegree: {},
    temperature: '90',
    emoji: 'Enabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultJobDescriptionInput: JobDescriptionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    topics: [],
    intent: '',
    lengthInput: 'Short',
    jobTitle: '',
    jobTopics: '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    toneDegree: {},
    temperature: '90',
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultAudienceChangerInput: AudienceChangerInput = {
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    toneDegree: {},
    temperature: '90',
    text: '',
  };

  const defaultFormatChangerInput: FormatChangerInput = {
    format: '',
    toneDegree: {},
    temperature: '90',
    text: '',
  };

  const defaultToneChangerInput: ToneChangerInput = {
    tone: [],
    toneDegree: {},
    temperature: '90',
    text: '',
  };

  const defaultPASFrameworkInput: PASFrameworkInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultSeasonalHolidayInput: SeasonalHolidayInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    age: currentAudPersona?.age ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    seasonalTopic: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultDiscountPromotionInput: DiscountPromotionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    format: '',
    discountDetails: '',
    duration: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultGiveAwayPromotionInput: GiveAwayPromotionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    prizeDetails: '',
    entryDetails: '',
    duration: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultLinkedInCompanyBioInput: LinkedInCompanyBioInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    topics: [],
    emoji: 'Disabled',
    hashtag: 'Disabled',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultLinkedInDirectMessageInput: LinkedInDirectMessageInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    recipientPersonalInfo: '',
    messageGoal: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultYoutubeChannelDescriptionInput: YoutubeChannelDescriptionInput =
    {
      brand: currentBrandPersona?.brand ?? '',
      brandPositioning: currentBrandPersona?.brandPositioning ?? '',
      brandDescription: currentBrandPersona?.description ?? '',
      tone: currentBrandPersona?.tone ?? [],
      website: currentBrandPersona?.website ?? '',
      industry: currentBrandPersona?.industry ?? '',
      painpoint: currentAudPersona?.painpoints ?? '',
      goals: currentAudPersona?.goals ?? '',
      barriers: currentAudPersona?.barriers ?? '',
      triggers: currentAudPersona?.triggers ?? '',
      location: currentAudPersona?.location ?? [],
      occupation: currentAudPersona?.occupation ?? [],
      audience: currentAudPersona?.audience ?? '',
      brandPic: currentBrandPersona?.profilePic ?? '',
      audPic: currentAudPersona?.profilePic ?? '',
      age: currentAudPersona?.age ?? [],
      channelDescription: '',
      channelName: '',
      toneDegree: {},
      temperature: '90',
      brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
      brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
        { replaceThis: '', withThis: '' },
      ],
      brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
    };

  const defaultProductDescriptionInput: ProductDescriptionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    extraProductDetails: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultMetaAdsInput: MetaAdsInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    keywords: '',
    description: '',
    lengthInput: InputLength.SHORT,
    temperature: '90',
    multipleOutputs: OutputQuantity['MULTIPLE'],
    tone: ['TikTok suggested tone'],
    toneDegree: {},
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultGoogleAdsInput: GoogleAdsInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    keywords: '',
    description: '',
    temperature: '90',
    tone: ['TikTok suggested tone'],
    toneDegree: {},
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultValuePropInput: ValuePropInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    toneDegree: {},
    temperature: '90',
    topics: [],
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultYoutubeVideoDescriptionInput: YoutubeVideoDescriptionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    videoDescription: '',
    videoTitle: '',
    callToAction: '',
    toneDegree: {},
    temperature: '90',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultBrandMissionInput: BrandMissionInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    brandGoal: '',
    toneDegree: {},
    temperature: '90',
    topics: [],
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultYoutubeVideoScriptInput: YoutubeVideoScriptInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    topics: [],
    videoPurpose: [],
    videoTopic: '',
    temperature: '90',
    toneDegree: {},
    emoji: 'Disabled',
    hashtag: 'Disabled',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const defaultShortVideoScriptInput: ShortVideoScriptInput = {
    brand: currentBrandPersona?.brand ?? '',
    brandPositioning: currentBrandPersona?.brandPositioning ?? '',
    brandDescription: currentBrandPersona?.description ?? '',
    tone: currentBrandPersona?.tone ?? [],
    website: currentBrandPersona?.website ?? '',
    industry: currentBrandPersona?.industry ?? '',
    painpoint: currentAudPersona?.painpoints ?? '',
    goals: currentAudPersona?.goals ?? '',
    barriers: currentAudPersona?.barriers ?? '',
    triggers: currentAudPersona?.triggers ?? '',
    location: currentAudPersona?.location ?? [],
    occupation: currentAudPersona?.occupation ?? [],
    audience: currentAudPersona?.audience ?? '',
    brandPic: currentBrandPersona?.profilePic ?? '',
    audPic: currentAudPersona?.profilePic ?? '',
    age: currentAudPersona?.age ?? [],
    topics: [],
    videoTheme: [],
    videoTopic: '',
    temperature: '90',
    toneDegree: {},
    emoji: 'Disabled',
    hashtag: 'Disabled',
    product: userData?.recentProduct ?? '',
    productDescription: userData?.recentProductDescription ?? '',
    videoLength: ShortVideoLength.THIRTY,
    influencer: '',
    brandAvoidArray: currentBrandPersona?.brandNoNoWords ?? [''],
    brandReplacementArray: currentBrandPersona?.brandReplacementWords ?? [
      { replaceThis: '', withThis: '' },
    ],
    brandSparinglyArray: currentBrandPersona?.brandUseSparinglyWords ?? [''],
  };

  const [formType, setFormType] = useState<FormType>(FormType.SOCIAL);
  const [blogInput, setBlogInput] = useState<BlogInput | undefined>(
    defaultBlogInput
  );
  const [personaBlogInput, setPersonaBlogInput] = useState<
    PersonaBlogInput | undefined
  >(defaultPersonaBlogInput);
  const [emailInput, setEmailInput] = useState<EmailInput | undefined>(
    defaultEmailInput
  );
  const [personaEmailInput, setPersonaEmailInput] = useState<
    PersonaEmailInput | undefined
  >(defaultPersonaEmailInput);
  const [socialInput, setSocialInput] = useState<SocialInput | undefined>(
    defaultSocialInput
  );
  const [personaSocialInput, setPersonaSocialInput] = useState<
    PersonaSocialInput | undefined
  >(defaultPersonaSocialInput);
  const [tiktokCaptionInput, setTikTokCaptionInput] = useState<
    TikTokCaptionInput | undefined
  >(defaultTikTokCaptionInput);

  const [marketingIdeasInput, setMarketingIdeasInput] =
    useState<MarketingIdeasInput>(defaultMarketingIdeasInput);
  const [personaMarketingIdeasInput, setPersonaMarketingIdeasInput] =
    useState<PersonaMarketingIdeasInput>(defaultPersonaMarketingIdeasInput);

  const [contentImproverInput, setContentImproverInput] =
    useState<ContentImproverInput>(defaultContentImproverInput);
  const [audienceRetargetInput, setAudienceRetargetInput] =
    useState<AudienceRetargetInput>(defaultAudienceRetargetInput);

  const [heroHeaderInput, setHeroHeaderInput] =
    useState<HeroHeaderInput>(defaultHeroHeader);
  const [personaHeroHeaderInput, setPersonaHeroHeaderInput] =
    useState<PersonaHeroHeaderInput>(defaultPersonaHeroHeader);
  const [subheaderInput, setSubheaderInput] =
    useState<SubheaderInput>(defaultHeroHeader);
  const [personaSubheaderInput, setPersonaSubheaderInput] =
    useState<PersonaSubheaderInput>(defaultPersonaSubheaderInput);
  const [featureDescriptionInput, setFeatureDescriptionInput] =
    useState<FeatureDescriptionInput>(defaultFeatureDescriptionInput);
  const [personaFeatureDescriptionInput, setPersonaFeatureDescriptionInput] =
    useState<PersonaFeatureDescriptionInput>(
      defaultPersonaFeatureDescriptionInput
    );
  const [callToActionInput, setCallToActionInput] =
    useState<CallToActionInput>(defaultHeroHeader);
  const [personaCallToActionInput, setPersonaCallToActionInput] =
    useState<PersonaCallToActionInput>(defaultPersonaCallToActionInput);

  const [personaNewsletterInput, setPersonaNewsletterInput] =
    useState<PersonaNewsletterInput>(defaultPersonaNewsletterInput);

  const [audienceChangerInput, setAudienceChangerInput] =
    useState<AudienceChangerInput>(defaultAudienceChangerInput);
  const [formatChangerInput, setFormatChangerInput] =
    useState<FormatChangerInput>(defaultFormatChangerInput);
  const [toneChangerInput, setToneChangerInput] = useState<ToneChangerInput>(
    defaultToneChangerInput
  );

  const [pasFrameworkInput, setPASFrameworkInput] = useState<PASFrameworkInput>(
    defaultPASFrameworkInput
  );

  const [seasonalHolidayInput, setSeasonalHolidayInput] =
    useState<SeasonalHolidayInput>(defaultSeasonalHolidayInput);
  const [discountPromotionInput, setDiscountPromotionInput] =
    useState<DiscountPromotionInput>(defaultDiscountPromotionInput);
  const [giveawayPromotionInput, setGiveAwayPromotionInput] =
    useState<GiveAwayPromotionInput>(defaultGiveAwayPromotionInput);

  const [productDescriptionInput, setProductDescriptionInput] =
    useState<ProductDescriptionInput>(defaultProductDescriptionInput);
  const [metaAdsInput, setMetaAdsInput] =
    useState<MetaAdsInput>(defaultMetaAdsInput);
  const [googleAdsInput, setGoogleAdsInput] = useState<GoogleAdsInput>(
    defaultGoogleAdsInput
  );

  const [brandMissionInput, setBrandMissionInput] = useState<BrandMissionInput>(
    defaultBrandMissionInput
  );

  const [valuePropInput, setValuePropInput] = useState<ValuePropInput>(
    defaultValuePropInput
  );

  const [linkedinCompanyBioInput, setLinkedInCompanyBioInput] =
    useState<LinkedInCompanyBioInput>(defaultLinkedInCompanyBioInput);
  const [linkedinDirectMessageInput, setLinkedInDirectMessageInput] =
    useState<LinkedInDirectMessageInput>(defaultLinkedInDirectMessageInput);

  const [youtubeChannelDescriptionInput, setYoutubeChannelDescriptionInput] =
    useState<YoutubeChannelDescriptionInput>(
      defaultYoutubeChannelDescriptionInput
    );
  const [youtubeVideoDescriptionInput, setYoutubeVideoDescriptionInput] =
    useState<YoutubeVideoDescriptionInput>(defaultYoutubeVideoDescriptionInput);
  const [youtubeVideoScriptInput, setYoutubeVideoScriptInput] =
    useState<YoutubeVideoScriptInput>(defaultYoutubeVideoScriptInput);
  const [shortVideoScriptInput, setShortVideoScriptInput] =
    useState<ShortVideoScriptInput>(defaultShortVideoScriptInput);

  const [jobDescriptionInput, setJobDescriptionInput] =
    useState<JobDescriptionInput>(defaultJobDescriptionInput);

  const [showAdvancedInputs, setShowAdvancedInputs] = useState<boolean>(false);

  const [generatedOutput, setGeneratedOutput] = useState<GeneratedResponse[]>(
    []
  );
  const [editedOutput, setEditedOutput] = useState<GeneratedResponse[]>([]);

  const [canKeepGoing, setCanKeepGoing] = useState<boolean>(false);

  const [lastGeneratedFormat, setLastGeneratedFormat] =
    useState<GeneratedFormat>();

  const [loading, setLoading] = useState<boolean>(false);
  const [isStateSetByJumpBackIn, setIsStateSetByJumpBackIn] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [wordLog, setWordLog] = useState('');
  const [jumpBackInType, setJumpBackInType] = useState('');
  const [jumpBackInValues, setJumpBackInValues] = useState<any>();

  useEffect(() => {
    if (isStateSetByJumpBackIn) {
      const closeTimeout = setTimeout(() => {
        setIsStateSetByJumpBackIn(false);
      }, 5000);
      return () => clearTimeout(closeTimeout);
    }
  }, [isStateSetByJumpBackIn]);

  // useEffect(() => {
  //   console.log(personaEmailInput, 'peronsaEmailInput');
  // }, [personaEmailInput]);

  // useEffect(() => {
  //   console.log('a change has been made!')
  //   console.log(personaSocialInput);
  // }, [personaSocialInput]);

  useEffect(() => {
    if (router && router.query.formType) {
      setFormType(router.query.formType as FormType);
      // If the user is creating a campaign, set a mainGoal
      if (router.query.campaignGoal) {
        const queryCampaignGoal = router.query.campaignGoal as string;
        switch (router.query.formType as FormType) {
          case FormType.SOCIAL:
            defaultSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.EMAIL:
            defaultEmailInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONAEMAIL:
            defaultPersonaEmailInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.BLOG:
            defaultBlogInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONABLOG:
            defaultPersonaBlogInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONA:
            defaultPersonaSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONA_TWITTER:
            defaultPersonaSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONA_INSTAGRAM:
            defaultPersonaSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONA_FACEBOOK:
            defaultPersonaSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.PERSONA_LINKEDIN:
            defaultPersonaSocialInput.campaignGoal = queryCampaignGoal;
            break;
          case FormType.TIKTOK:
            defaultTikTokCaptionInput.campaignGoal = queryCampaignGoal;
            break;
        }
      }
    }

    if (router && router.query.prefillData) {
      const prefillData = JSON.parse(router.query.prefillData as string) as
        | BlogInput
        | PersonaBlogInput
        | EmailInput
        | PersonaEmailInput
        | SocialInput
        | PersonaSocialInput
        | TikTokCaptionInput;
      setBlogInput({ ...defaultBlogInput, ...prefillData });
      setPersonaBlogInput({ ...defaultPersonaBlogInput, ...prefillData });
      setEmailInput({ ...defaultEmailInput, ...prefillData });
      setPersonaEmailInput({ ...defaultPersonaEmailInput, ...prefillData });
      setSocialInput({ ...defaultSocialInput, ...prefillData });
      setPersonaSocialInput({ ...defaultPersonaSocialInput, ...prefillData });
      setTikTokCaptionInput({ ...defaultTikTokCaptionInput, ...prefillData });
    } else if (router && isStateSetByJumpBackIn) {
      console.log('state from jumpback in');
      console.log(jumpBackInValues, ' this is jumpbackinvalues before set');
      console.log(jumpBackInType, 'this is jumpbackintype');
      if (jumpBackInType === FormType.BLOG) {
        setBlogInput(jumpBackInValues as BlogInput);
      }
      if (jumpBackInType === FormType.PERSONABLOG) {
        setPersonaBlogInput(jumpBackInValues as PersonaBlogInput);
      }
      if (jumpBackInType === FormType.EMAIL) {
        setEmailInput(jumpBackInValues as EmailInput);
      }
      if (jumpBackInType === FormType.PERSONAEMAIL) {
        setPersonaEmailInput(jumpBackInValues as PersonaEmailInput);
      }
      if (jumpBackInType === FormType.SOCIAL) {
        setSocialInput(jumpBackInValues as SocialInput);
      }
      if (
        jumpBackInType === 'instagram' ||
        jumpBackInType === 'facebook' ||
        jumpBackInType === 'linkedin' ||
        jumpBackInType === 'twitter'
      ) {
        console.log('updating persona social input');
        console.log(personaSocialInput, 'initial persona social input');
        // setPersonaSocialInput(jumpBackInValues as PersonaSocialInput);
        console.log(personaSocialInput, 'after persona social input');
      }
      if (jumpBackInType === FormType.TIKTOK) {
        setTikTokCaptionInput(jumpBackInValues as TikTokCaptionInput);
      }
    } else {
      setBlogInput(defaultBlogInput);
      setPersonaBlogInput(defaultPersonaBlogInput);
      setEmailInput(defaultEmailInput);
      setPersonaEmailInput(defaultPersonaEmailInput);
      setSocialInput(defaultSocialInput);
      setPersonaSocialInput(defaultPersonaSocialInput);
      setTikTokCaptionInput(defaultTikTokCaptionInput);
    }
  }, [router]);

  async function submitContentForm<
    T extends
      | BlogInput
      | SocialInput
      | EmailInput
      | PersonaEmailInput
      | PersonaSocialInput
      | TikTokCaptionInput
      | PersonaBlogInput
      | PersonaNewsletterInput
      | SeasonalHolidayInput
      | DiscountPromotionInput
      | GiveAwayPromotionInput
      | LinkedInCompanyBioInput
      | LinkedInDirectMessageInput
      | YoutubeChannelDescriptionInput
      | YoutubeVideoDescriptionInput
      | YoutubeVideoScriptInput
      | ShortVideoScriptInput
      | JobDescriptionInput
      | MetaAdsInput
      | GoogleAdsInput
  >({
    values,
    formType,
    actions,
    keepPreviousResponses,
  }: FormSubmissionInputs<T>) {
    // If tones were not changed, they will be undefined
    const newToneDegree = values.toneDegree;
    values.tone.forEach((tone) => {
      if (values.toneDegree[tone] || tone.includes('suggested')) return;
      newToneDegree[tone] = '50';
    });
    values.toneDegree = newToneDegree;

    let apiEndpoint = '/api/openai/';
    switch (formType) {
      case FormType.BLOG:
        apiEndpoint += 'generateBlog';
        break;
      case FormType.PERSONABLOG:
        apiEndpoint += 'generatePersonaBlog';
        break;
      case FormType.EMAIL:
        apiEndpoint += 'generateEmail';
        break;
      case FormType.PERSONAEMAIL:
        apiEndpoint += 'generatePersonaEmail';
        break;
      case FormType.SOCIAL:
      default:
        apiEndpoint += 'generateSocial';
        break;
      case FormType.PERSONA:
        apiEndpoint += 'generatePersonaSocial';
        break;
      case FormType.PERSONA_TWITTER:
        apiEndpoint += 'generatePersonaSocial';
        break;
      case FormType.PERSONA_INSTAGRAM:
        apiEndpoint += 'generatePersonaSocial';
        break;
      case FormType.PERSONA_LINKEDIN:
        apiEndpoint += 'generatePersonaSocial';
        break;
      case FormType.PERSONA_FACEBOOK:
        apiEndpoint += 'generatePersonaSocial';
        break;
      case FormType.TIKTOK:
        apiEndpoint += 'generateTikTokCaption';
        break;
      case FormType.PERSONA_NEWSLETTER:
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.PROMOTIONAL_NEWSLETTER:
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.PRODUCT_UPDATE_NEWSLETTER:
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.EDUCATIONAL_NEWSLETTER:
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.ORGANIZATIONAL_NEWSLETTER:
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.MARKETINGIDEAS:
        apiEndpoint += 'generateMarketingIdeas';
        break;
      case FormType.SEASONAL:
        apiEndpoint += 'generateSeasonalHoliday';
        break;
      case FormType.DISCOUNT:
        apiEndpoint += 'generateDiscountPromotion';
        break;
      case FormType.GIVEAWAY:
        apiEndpoint += 'generateGiveAwayPromotion';
        break;
      case FormType.LINKEDIN_COMPANY_BIO:
        apiEndpoint += 'generateLinkedInCompanyBio';
        break;
      case FormType.LINKEDIN_DIRECT_MESSAGE:
        apiEndpoint += 'generateLinkedInDirectMessage';
        break;
      case FormType.YOUTUBE_CHANNEL_DESCRIPTION:
        apiEndpoint += 'generateYoutubeChannelDescription';
        break;
      case FormType.YOUTUBE_VIDEO_DESCRIPTION:
        apiEndpoint += 'generateYoutubeVideoDescription';
        break;
      case FormType.YOUTUBE_VIDEO_SCRIPT:
        apiEndpoint += 'generateYoutubeVideoScript';
        break;
      case FormType.SHORT_VIDEO_SCRIPT:
        apiEndpoint += 'generateShortVideoScript';
        break;
      case FormType.JOB_DESCRIPTION:
        apiEndpoint += 'generateJobDescription';
        break;
      case FormType.META_ADS:
        apiEndpoint += 'generateMetaAds';
        break;
      case FormType.GOOGLE_ADS:
        apiEndpoint += 'generateGoogleAds';
        break;
    }

    await submitForm({
      formSubmissionInputs: {
        values,
        formType,
        actions,
        keepPreviousResponses,
      },
      apiEndpoint,
    });
  }

  async function submitBrainstormForm<
    T extends MarketingIdeasInput | PersonaMarketingIdeasInput
  >({ values, formType, actions }: FormSubmissionInputs<T>) {
    let apiEndpoint = '/api/openai/';
    switch (formType) {
      default:
      case FormType.MARKETINGIDEAS:
        apiEndpoint += 'generateMarketingIdeas';
        break;
      case FormType.PERSONAMARKETINGIDEAS:
        apiEndpoint += 'generatePersonaMarketingIdeas';
    }

    await submitForm({
      formSubmissionInputs: {
        values,
        formType,
        actions,
        keepPreviousResponses: false,
      },
      apiEndpoint,
    });
  }

  async function submitToolkitForm<
    T extends
      | ContentImproverInput
      | AudienceRetargetInput
      | AudienceChangerInput
      | FormatChangerInput
      | ToneChangerInput
      | PASFrameworkInput
      | ProductDescriptionInput
      | ValuePropInput
      | BrandMissionInput
  >({ values, formType, actions }: FormSubmissionInputs<T>) {
    let apiEndpoint = '/api/openai/';
    switch (formType) {
      case FormType.AUDIENCE_RETARGET:
        apiEndpoint += 'retargetAudience';
        break;
      default:
      case FormType.CONTENT_IMPROVER:
        apiEndpoint += 'improveContent';
        break;
      case FormType.AUDIENCE_CHANGER:
        apiEndpoint += 'generateAudienceChanger';
        break;
      case FormType.FORMAT_CHANGER:
        apiEndpoint += 'generateFormatChanger';
        break;
      case FormType.TONE_CHANGER:
        apiEndpoint += 'generateToneChanger';
        break;
      case FormType.PAS_FRAMEWORK:
        apiEndpoint += 'generatePASFramework';
        break;
      case FormType.PRODUCT_DESCRIPTION:
        apiEndpoint += 'generateProductDescription';
        break;
      case FormType.VALUE_PROP:
        apiEndpoint += 'generateValueProp';
        break;
      case FormType.BRAND_MISSION:
        apiEndpoint += 'generateBrandMission';
        break;
    }

    await submitForm({
      formSubmissionInputs: {
        values,
        formType,
        actions,
        keepPreviousResponses: false,
      },
      apiEndpoint,
    });
  }

  async function submitWebsiteForm<T extends AllWebsiteInputs>({
    values,
    formType,
    actions,
    keepPreviousResponses,
  }: FormSubmissionInputs<T>) {
    let apiEndpoint = '/api/openai/website/';
    switch (formType) {
      default:
      case FormType.HERO_HEADER:
        apiEndpoint += 'generateHeroHeader';
        break;
      case FormType.PERSONA_HERO_HEADER:
        apiEndpoint += 'generatePersonaHeroHeader';
        break;
      case FormType.SUBHEADER:
        apiEndpoint += 'generateSubheader';
        break;
      case FormType.PERSONA_SUBHEADER:
        apiEndpoint += 'generatePersonaSubheader';
        break;
      case FormType.FEATURE_DESCRIPTION:
        apiEndpoint += 'generateFeatureDescription';
        break;
      case FormType.PERSONA_FEATURE_DESCRIPTION:
        apiEndpoint += 'generatePersonaFeatureDescription';
        break;
      case FormType.CALL_TO_ACTION:
        apiEndpoint += 'generateCallToAction';
        break;
      case FormType.PERSONA_CALL_TO_ACTION:
        apiEndpoint += 'generatePersonaCallToAction';
        break;
    }

    await submitForm({
      formSubmissionInputs: {
        values,
        formType,
        actions,
        keepPreviousResponses,
      },
      apiEndpoint,
    });
  }

  // Helper function to submit inputs to the text generation endpoint
  async function submitForm<
    T extends
      | BlogInput
      | PersonaBlogInput
      | SocialInput
      | EmailInput
      | PersonaEmailInput
      | PersonaSocialInput
      | TikTokCaptionInput
      | MarketingIdeasInput
      | PersonaMarketingIdeasInput
      | PersonaNewsletterInput
      | AudienceChangerInput
      | FormatChangerInput
      | ToneChangerInput
      | PASFrameworkInput
      | SeasonalHolidayInput
      | DiscountPromotionInput
      | GiveAwayPromotionInput
      | LinkedInCompanyBioInput
      | LinkedInDirectMessageInput
      | YoutubeChannelDescriptionInput
      | YoutubeVideoDescriptionInput
      | YoutubeVideoScriptInput
      | ShortVideoScriptInput
      | JobDescriptionInput
      | ProductDescriptionInput
      | MetaAdsInput
      | GoogleAdsInput
      | ValuePropInput
      | BrandMissionInput
      | ContentImproverInput
      | AudienceRetargetInput
      | AllWebsiteInputs
  >({
    formSubmissionInputs,
    apiEndpoint,
  }: {
    formSubmissionInputs: FormSubmissionInputs<T>;
    apiEndpoint: string;
  }) {
    const { values, formType, actions, keepPreviousResponses } =
      formSubmissionInputs;

    setLoading(true);
    setCanKeepGoing(false);
    const finalOutput = keepPreviousResponses ? editedOutput : [];
    try {
      const { data: generatedResponseData } = await axios.post<
        GeneratedResponse[]
      >(apiEndpoint, { ...values, advancedInputs: showAdvancedInputs });
      const output: GeneratedResponse[] =
        generatedResponseData.length > 0
          ? generatedResponseData
          : [
              {
                text: '⚠️ Something went wrong when generating your response, please try again.',
                canKeepGoing: false,
              },
            ];
      finalOutput.push(...output);
      setEditedOutput(finalOutput);
      setGeneratedOutput(finalOutput);
      const initialWords = generatedOutput.map(({ text }) => text);
      const wordsCombined = initialWords.toString();
      setWordLog(wordsCombined);
      setButtonClicked(true);

      // const wordCount = wordsCombined.split(' ').length;
      setCanKeepGoing(generatedResponseData[0].canKeepGoing);
      setLastGeneratedFormat({
        formType,
        platform:
          formType === FormType.PERSONA
            ? (values as SocialInput).platform
            : undefined,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400 && actions) {
        // TODO: Fix Error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        actions.setErrors(err.response?.data as T);
      } else {
        const errorData = err.response?.data as APIErrors;
        const errorMessage =
          typeof errorData?.error === 'string' && errorData?.error
            ? errorData.error
            : 'Something went wrong, please try again';
        finalOutput.push({ text: errorMessage, canKeepGoing: false });
        setEditedOutput(finalOutput);
        setGeneratedOutput(finalOutput);
      }
    } finally {
      updateWords(JSON.stringify(finalOutput));
      setLoading(false);
    }
  }

  const updateWords = async (words: string) => {
    try {
      await axios.post('/api/openai/lib/logWordCount', words);
      console.log('word count updated');
    } catch (error) {
      const err = error as AxiosError;
    }
  };

  return (
    <FormContext.Provider
      value={{
        formType,
        setFormType,
        blogInput,
        setBlogInput,
        personaBlogInput,
        setPersonaBlogInput,
        emailInput,
        setEmailInput,
        personaEmailInput,
        setPersonaEmailInput,
        socialInput,
        setSocialInput,
        personaSocialInput,
        setPersonaSocialInput,
        tiktokCaptionInput,
        setTikTokCaptionInput,

        marketingIdeasInput,
        setMarketingIdeasInput,
        personaMarketingIdeasInput,
        setPersonaMarketingIdeasInput,

        contentImproverInput,
        setContentImproverInput,
        audienceRetargetInput,
        setAudienceRetargetInput,
        featureDescriptionInput,
        setFeatureDescriptionInput,
        personaFeatureDescriptionInput,
        setPersonaFeatureDescriptionInput,
        callToActionInput,
        setCallToActionInput,
        personaCallToActionInput,
        setPersonaCallToActionInput,

        heroHeaderInput,
        setHeroHeaderInput,
        personaHeroHeaderInput,
        setPersonaHeroHeaderInput,
        subheaderInput,
        setSubheaderInput,
        personaSubheaderInput,
        setPersonaSubheaderInput,

        personaNewsletterInput,
        setPersonaNewsletterInput,

        audienceChangerInput,
        setAudienceChangerInput,
        formatChangerInput,
        setFormatChangerInput,
        toneChangerInput,
        setToneChangerInput,

        pasFrameworkInput,
        setPASFrameworkInput,

        seasonalHolidayInput,
        setSeasonalHolidayInput,
        discountPromotionInput,
        setDiscountPromotionInput,
        giveawayPromotionInput,
        setGiveAwayPromotionInput,

        linkedinCompanyBioInput,
        setLinkedInCompanyBioInput,
        linkedinDirectMessageInput,
        setLinkedInDirectMessageInput,

        youtubeChannelDescriptionInput,
        setYoutubeChannelDescriptionInput,
        youtubeVideoDescriptionInput,
        setYoutubeVideoDescriptionInput,
        youtubeVideoScriptInput,
        setYoutubeVideoScriptInput,
        shortVideoScriptInput,
        setShortVideoScriptInput,

        jobDescriptionInput,
        setJobDescriptionInput,

        productDescriptionInput,
        setProductDescriptionInput,
        metaAdsInput,
        setMetaAdsInput,
        googleAdsInput,
        setGoogleAdsInput,

        valuePropInput,
        setValuePropInput,

        brandMissionInput,
        setBrandMissionInput,

        showAdvancedInputs,
        setShowAdvancedInputs,

        generatedOutput,
        setGeneratedOutput,
        editedOutput,
        setEditedOutput,
        canKeepGoing,
        setCanKeepGoing,

        lastGeneratedFormat,

        submitContentForm,
        submitBrainstormForm,
        submitToolkitForm,
        submitWebsiteForm,

        updateWords,

        loading,
        setLoading,
        isStateSetByJumpBackIn,
        setIsStateSetByJumpBackIn,
        jumpBackInType,
        setJumpBackInType,
        jumpBackInValues,
        setJumpBackInValues,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = (): FormContextInterface => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('FormContext must be within FormContextProvider');
  }

  return context;
};
