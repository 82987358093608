import { FC } from 'react';

type NewsletterSvgProps = {
  stroke?: string;
};

export const NewsletterSvg: FC<NewsletterSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="14" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_347_1662)">
<path d="M1.66602 8.33333C1.66602 6.5434 3.20398 5.13941 4.99332 5.18514C6.42576 5.22174 8.02896 5.19919 9.16602 5C11.9048 4.52021 14.9993 2.5 14.9993 2.5V14.1667C14.9993 14.1667 11.9048 12.1465 9.16602 11.6667C8.02897 11.4675 6.42576 11.4449 4.99333 11.4815C3.20398 11.5273 1.66602 10.1233 1.66602 8.33333V8.33333Z" stroke={stroke} strokeWidth="2"/>
<path d="M10.8333 12.4998V17.3991C10.8333 18.3996 9.52679 18.78 8.98967 17.936L5 11.6665" stroke={stroke} strokeWidth="2"/>
<path d="M15 10.8335C16.3807 10.8335 17.5 9.71421 17.5 8.3335C17.5 6.95278 16.3807 5.8335 15 5.8335" stroke={stroke} strokeWidth="2"/>
</g>
<defs>
<clipPath id="clip0_347_1662">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>



  );
};
