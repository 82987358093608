import { FC } from 'react';

type ContentEngagerSvgProps = {
  stroke?: string;
};

export const ContentEngagerSvg: FC<ContentEngagerSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.6667 4.81246C5.19801 3.49389 7.00003 1.16663 7.00003 1.16663C7.00003 1.16663 8.80205 3.49389 9.33336 4.81246C10.1926 6.94488 8.75003 10.5 8.75003 10.5H5.25003C5.25003 10.5 3.80744 6.94488 4.6667 4.81246Z" stroke={stroke}/>
<path d="M8.74984 10.5H11.6665L9.62484 7" stroke={stroke}/>
<path d="M5.25016 10.5H2.3335L4.37516 7" stroke={stroke}/>
<path d="M5.8459 11.5417C5.99853 11.0478 6.22983 10.5 6.22983 10.5H7.76557C7.76557 10.5 7.96589 11.0603 8.14948 11.5417C8.33306 12.023 7.00016 13.125 7.00016 13.125C7.00016 13.125 5.69326 12.0356 5.8459 11.5417Z" stroke={stroke}/>
<path d="M4.6665 4.66663C4.6665 4.66663 5.5415 5.24996 6.99984 5.24996C8.45817 5.24996 9.33317 4.66663 9.33317 4.66663" stroke={stroke}/>
</svg>






  );
};
