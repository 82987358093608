import { FC } from 'react';

import styles from './SimpleBadge.module.css';

type SimpleBadgeProps = {
  text: string;
};

export const SimpleBadge: FC<SimpleBadgeProps> = ({ text }) => {
  return <p className={styles['container']}>{text}</p>;
};
