import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { BasicNavbar } from '../navbars/BasicNavbar';
import Image from 'next/image';

import styles from './AuthGuard.module.css';
import axios from 'axios';

const loadingTextArray: string[] = [
  // '🛠️ Assembling SuperCopy...',
  // "📝 Making sure all the i's have dots...",
  '🧠 Convincing AI not to turn evil...',
];

export const AuthGuard = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null => {
  const { attemptedSignIn, isSignedIn, userData } = useAuthContext();
  const router = useRouter();

  const [loadingText, setLoadingText] = useState<string>(loadingTextArray[0]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * loadingTextArray.length);
    setLoadingText(loadingTextArray[randomIndex]);
  }, []);

  useEffect(() => {
    if (attemptedSignIn) {
      if (!isSignedIn) {
        // router.push('/');
         router.push(`/signin?redirect=${router.route}`, '/signin');
      }
    }
  }, [attemptedSignIn, router, isSignedIn]);

  useEffect(() => {
    if(attemptedSignIn){
      axios.post('/api/user/checkTrialSettings');
      axios.post('/api/user/checkWordCountRefresh');
    }
  }, [])

  if (
    attemptedSignIn &&
    isSignedIn &&
    userData &&
    userData.finishedOnboarding && // Must have finished onboarding
    userData.stripe?.subscription // Must have a subscription
    || 
    attemptedSignIn &&
    isSignedIn &&
    userData &&
    userData.freeTrial === true
    
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <BasicNavbar />
      <div className={styles['container']}>
      <h2>☕ Preparing SuperCopy's coffee with cream and sugar...</h2>
      <span>&nbsp;&nbsp;</span>
      <Image
            src="/demoGifs/animation.gif"
            alt=""
            objectFit="contain"
            height={25}
            width={50}
          />
        <span>&nbsp;&nbsp;</span>
      <h3>If not redirected in 10 seconds, please refresh the page.</h3>
    </div>
    </>
  );
};
