export const MarketingIdeasIntents: Record<string, string> = {
  'Nurture current audience': 'Nurture current audience',
  'Generate new audience': 'Generate new audience',
  'Sell a product or service': 'Sell a product or service',
};

export const ContentImproverIntents: Record<string, string> = {
  'Rewrite to be more engaging':
    'Creatively rewrite and improve this copy to be more interesting and engaging',
  'Simplify to make it more concise':
    'Simply rewrite this copy to be much shorter and more concise',
  'Increase the overall length':
    'Rewrite and extend the length of this copy by adding more details',
};

export const EmojiHashtagIntents: Record<string, string> = {
  'Enabled': 'Enabled',
  'Disabled': 'Disabled',
};

export const LengthIntents: Record<string, string> = {
  'Short': 'Short',
  'Regular': 'Regular',
};

