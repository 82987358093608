import { FC } from 'react';

type HomeMinimalSvgProps = {
  stroke?: string;
};

export const HomeMinimalSvg: FC<HomeMinimalSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.69833C1 5.74478 1.45332 4.84801 2.22115 4.28261L6 1.5L9.77885 4.28261C10.5467 4.84801 11 5.74477 11 6.69833V10C11 11.3807 9.88071 12.5 8.5 12.5H3.5C2.11929 12.5 1 11.3807 1 10V6.69833Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
