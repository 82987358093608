// TODO: FIX ERROR
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { BasicNavbar } from '../components/shared/navbars/BasicNavbar';
import { AuthContextProvider } from '../contexts/AuthContext';
import { AuthGuard } from '../components/shared/auth/AuthGuard';
import { useEffect } from 'react';
import * as FullStory from '@fullstory/browser';
import { AdminContextProvider } from '../contexts/AdminContext';
import mixpanel from 'mixpanel-browser';
import Script from 'next/script';
import Head from 'next/head';
import { SWRConfig } from 'swr';
import axios from 'axios';
import { SideNavbar } from '../components/shared/navbars/SideNavbar';
import { SettingsContextProvider } from '../contexts/SettingsContext';
import { PersonaContextProvider } from '../contexts/PersonaContext';
import { Analytics } from '@vercel/analytics/react';
import Intercom from 'intercom-client';
import { CampaignNewContextProvider } from '../contexts/CampaignNewContext';
import { ResponseHistoryContextProvider } from '../contexts/ResponseHistoryContext';
import { FormContextProvider } from '../contexts/FormContext';
import { OutputAreaContextProvider } from '../contexts/OuputAreaContext';
import GTM from '../components/shared/GoogleTagManager/GTM';
import { FolderProvider } from '../contexts/FolderHistoryContext';
import { Auth0Provider } from '@auth0/auth0-react';

// interface Window {
//   intercomSettings: {
//     app_id: string;

//   };
// }

// interface CustomWindow extends Window {
//   Intercom: (...args: any[]) => void;
//   addEventListener?: typeof window.addEventListener;
//   attachEvent?: (type: string, listener: EventListenerOrEventListenerObject) => void;
// }

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    FullStory.init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORGID || '',
      devMode: process.env.NODE_ENV === 'development',
    });
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
      debug: process.env.NODE_ENV === 'development',
    });
  }, []);
  const { origin } =
    typeof window !== 'undefined' ? window.location : { origin: '' };

  // const onRedirectCallback = (appState: any) => {
  //   // Use the appState to redirect to the desired page with the query parameters
  //   history.push({
  //     pathname: appState?.targetUrl || '/',
  //     search: `?subscriptionType=${appState?.subscriptionType}&subscriptionLength=${appState?.subscriptionLength}`,
  //   });
  // };
  return (
    <div
      style={{
        backgroundColor: 'var(--ea-beige)',
      }}
    >
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
      </Head>
      <Script
        async
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-B2NQ7H87B8"
      ></Script>
      <Script strategy="afterInteractive" id="gtm">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${
              process.env.GOOGLE_AD_MANAGER_ID || ''
            }',{ 'debug_mode':true });
          `}
      </Script>
      <Script
        async
        src="https://cdn.tolt.io/tolt.js"
        data-tolt="133fbbc2-e079-432c-b3be-77d5deabc9c2"
      />
      <GTM gtmId="GTM-MQK5TTLT" />
      <Analytics />
      {/* <Script async src="https://w.appzi.io/w.js?token=SJsNk" /> */}

      <SWRConfig
        value={{
          fetcher: (url: string) =>
            axios.get(url).then((res) => res.data as JSON),
        }}
      >
        <Auth0Provider
          domain="dev-e3uie3mm810v747b.us.auth0.com"
          clientId="9mnCZml1rhYmbkTkF1ypdNmhGnBkTxCC"
          authorizationParams={{
            redirect_uri:
              process.env.NODE_ENV === 'development'
                ? 'http://localhost:3000/signin'
                : `${origin}/signin`,
            audience: 'https://dev-e3uie3mm810v747b.us.auth0.com/api/v2/',
            prompt: 'select_account',
          }}
        >
          <AuthContextProvider>
            {/* TODO: FIX ERROR */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {Component?.requireAuth ? (
              <AuthGuard>
                <SettingsContextProvider>
                  <PersonaContextProvider>
                    <CampaignNewContextProvider>
                      <FormContextProvider>
                        <OutputAreaContextProvider>
                          <ResponseHistoryContextProvider>
                            <FolderProvider>
                              <SideNavbar>
                                {
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  Component?.requireAdmin ? (
                                    <AdminContextProvider>
                                      <Component {...pageProps} />
                                    </AdminContextProvider>
                                  ) : (
                                    <AuthGuard>
                                      <Component {...pageProps} />
                                    </AuthGuard>
                                  )
                                }
                              </SideNavbar>
                            </FolderProvider>
                          </ResponseHistoryContextProvider>
                        </OutputAreaContextProvider>
                      </FormContextProvider>
                    </CampaignNewContextProvider>
                  </PersonaContextProvider>
                </SettingsContextProvider>
              </AuthGuard>
            ) : (
              <>
                <BasicNavbar />
                <Component {...pageProps} />
              </>
            )}
          </AuthContextProvider>
        </Auth0Provider>
      </SWRConfig>
    </div>
  );
}

export default MyApp;
