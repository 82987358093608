import { FC } from 'react';

type FormatChangerSvgProps = {
  stroke?: string;
};

export const FormatChangerSvg: FC<FormatChangerSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="14" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.8335 16L11.0002 20.1667L20.1668 16" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.8335 10.5834L11.0002 14.75L20.1668 10.5834" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.0002 1.83337L20.1668 5.16671L11.0002 9.33337L1.8335 5.16671L11.0002 1.83337Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>






  );
};
