import Image from 'next/image';
import { FC } from 'react';
import { NextImageBasicData } from '../../../types/Next';

import styles from './SimpleTag.module.css';

type SimpleTag = {
  image?: NextImageBasicData;
  text: string;
  backgroundColor?: string;
  color?: string;
  border?: string;
};

export const SimpleTag: FC<SimpleTag> = ({
  image,
  text,
  backgroundColor = '',
  color = '',
  border = '',
}) => {
  return (
    <div
      className={styles['container']}
      style={{
        backgroundColor,
        border,
      }}
    >
      {image && <Image {...image} alt="" objectFit="contain" layout="fixed" />}
      <p style={{ color }}>{text}</p>
    </div>
  );
};
