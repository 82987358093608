/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// // FolderContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { FolderContent } from '../types/FormTypes';
import useSWR from 'swr';
import { useAuthContext } from './AuthContext';
import axios from 'axios';
import { ContentResponsesOutput, FolderOutput } from '../types/API';
import mixpanel from 'mixpanel-browser';
import { ContentResponse } from '../types/FormTypes';
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  DocumentData,
} from 'firebase/firestore';
import { adminDb } from '../utils/firebase/adminConfig';
import { uuid } from 'uuidv4';
import { string } from 'yup';
import moment from 'moment';

interface FolderContentContextInterface {
  folders: FolderContent[] | undefined;
  mostRecentFolder: { folderName: string; folderID: string };
  setMostRecentFolder: Dispatch<
    SetStateAction<{ folderName: string; folderID: string }>
  >;
  activeFolderId: string | undefined;
  setActiveFolderId: Dispatch<SetStateAction<string>>;
  loadMoreFolders: () => Promise<void>;
  canLoadMoreFolders: boolean;
  setCanLoadMoreFolders: Dispatch<SetStateAction<boolean>>;
  folderContentSWR: FolderContent[] | undefined;
  mutateFolderSWR: () => Promise<void>;
  folderContentError: Error | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  navigateToFolder: (
    folderId: string,
    folderName: string,
    path: string,
    parentID: string | null,
    uid: string,
    time: number,
    lastEdit: number
  ) => Promise<void>;
  createFolder: (
    folderName: string,
    parentID: string | null
  ) => Promise<FolderContent | undefined>;
  deleteFolderOrContent: (itemID: string, isFolder: boolean) => Promise<void>;
  updateContent: (
    content: string,
    contentID: string,
    folderID?: string
  ) => Promise<void>;
  saveFileToFolder: (folderID: string, contentID: string) => Promise<void>;
  saveSuperChatToFolder: (
    folderID: string,
    response: string,
    brand: string,
    audience: string
  ) => Promise<void>;
  navigateToPathIndex: (index: number) => void;
  folderHistory: {
    folder: FolderContent;
    contents: ContentResponse[];
    subFolders: FolderContent[];
  }[];
  setFolderHistory: Dispatch<
    SetStateAction<
      {
        folder: FolderContent;
        contents: ContentResponse[];
        subFolders: FolderContent[];
      }[]
    >
  >;
  functionStillLoading: boolean;
}

const FolderContext = createContext<FolderContentContextInterface | undefined>(
  undefined
);

// export const useFolders = () => useContext(FolderContext);

export const FolderProvider = ({ children }: { children: ReactNode }) => {
  const { isSignedIn } = useAuthContext();
  const [folders, setFolders] = useState<FolderContent[]>();
  const [activeFolderId, setActiveFolderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [functionStillLoading, setFunctionStillLoading] = useState(false);
  const [canLoadMoreFolders, setCanLoadMoreFolders] = useState(false);
  const [folderHistory, setFolderHistory] = useState<
    {
      folder: FolderContent;
      contents: ContentResponse[];
      subFolders: FolderContent[];
    }[]
  >([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [contentResponses, setContentResponses] = useState<ContentResponse[]>();
  const [canLoadMoreContentResponses, setCanLoadMoreContentResponses] =
    useState<boolean>(false);
  const [mostRecentFolder, setMostRecentFolder] = useState<{
    folderName: string;
    folderID: string;
  }>({ folderName: '', folderID: '' });

  const {
    data: folderContentSWR,
    mutate: mutateFolderContentSWR,
    error: folderContentError,
  } = useSWR<FolderContent[], Error>(
    isSignedIn ? '/api/fileStructure/retrieveTopLevelItems' : null
  );

  useEffect(() => {
    if (folderContentSWR) {
      setFolders(folderContentSWR);
      // setCanLoadMoreFolders(folderContentSWR.canLoadMore);
    }
  }, [folderContentSWR]);

  const loadMoreFolders = async () => {
    if (!folders) return;
    setLoading(true);
    try {
      const { data: nextFolders } = await axios.get<FolderOutput>(
        `/api/responses/getChatHistory?startAfterId=${
          folders[folders.length - 1].id
        }`
      );
      const newFolders = [...folders, ...nextFolders.folderResponses];
      setFolders(newFolders);
      setCanLoadMoreFolders(nextFolders.canLoadMore);
      mutateFolderContentSWR();
      mixpanel.track('Load more folders', {
        totalNumResponsesLoaded: newFolders.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const mutateFolderSWR = async () => {
    try {
      await mutateFolderContentSWR();
      // console.log('Saved and updated');
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContents = async (folderId: string) => {
    setLoading(true);
    try {
      const { data: nextResponses } = await axios.post<ContentResponsesOutput>(
        '/api/fileStructure/fetchContent',
        folderId
      );
      console.log(nextResponses.contentResponses.length);
      console.log(nextResponses.canLoadMore);
      setContentResponses(nextResponses.contentResponses);
      setCanLoadMoreContentResponses(nextResponses.canLoadMore);
      setLoading(false);
      setLastVisible(
        nextResponses.contentResponses[
          nextResponses.contentResponses.length - 1
        ]
      );
      return contentResponses;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreResponses = async () => {
    if (!contentResponses) return;
    setLoading(true);
    try {
      const { data: nextContentResponses } =
        await axios.get<ContentResponsesOutput>(
          `/api/fileStructure/fetchContent?startAfterId=${
            contentResponses[contentResponses.length - 1].id
          }`
        );
      const newContentResponses = [
        ...contentResponses,
        ...nextContentResponses.contentResponses,
      ];
      setContentResponses(newContentResponses);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFolders = async (folderId: string) => {
    setLoading(true);
    const folders = await axios.post(
      '/api/fileStructure/fetchFolders',
      folderId
    );
    const subfolders = folders.data as FolderContent[];
    setLoading(false);
    return subfolders;
  };

  const navigateToFolder = async (
    folderId: string,
    folderName: string,
    path: string,
    parentID: string | null,
    uid: string,
    time: number,
    lastEdit: number
  ) => {
    setFunctionStillLoading(true);
    try {
      const { data: nextResponses } = await axios.post<ContentResponsesOutput>(
        '/api/fileStructure/fetchContent',
        folderId
      );
      setContentResponses(nextResponses.contentResponses);
      setCanLoadMoreContentResponses(nextResponses.canLoadMore);
      setLoading(false);
      setLastVisible(
        nextResponses.contentResponses[
          nextResponses.contentResponses.length - 1
        ]
      );

      const folders = await axios.post(
        '/api/fileStructure/fetchFolders',
        folderId
      );
      const subfolders = folders.data as FolderContent[];

      // Construct the new entry object
      const newEntry = {
        folder: {
          id: folderId,
          name: folderName,
          parentID: parentID,
          path: path,
          uid: uid,
          time: time,
          lastEdit: lastEdit,
        },
        contents: nextResponses.contentResponses ?? [],
        subFolders: subfolders,
      };

      // Update the folder history state
      setFolderHistory((prev) => [...prev, newEntry]);
    } catch (error) {
      console.log(error);
    } finally {
      // Set loading state to false once all operations are complete
      setFunctionStillLoading(false);
      console.log(folderHistory);
    }
  };

  const navigateToPathIndex = (index: number) => {
    setFolderHistory((prev) => prev.slice(0, index + 1));
  };

  const createFolder = async (
    folderName: string,
    parentFolderId: string | null
  ) => {
    setLoading(true);
    try {
      const random = uuid();
      const docID = random.slice(0, 25);

      let newPath = '';

      if (parentFolderId && parentFolderId !== null) {
        // Fetch the parent folder to get its path
        const parentFolder = await axios.post(
          '/api/fileStructure/fetchParentFolder',
          parentFolderId
        );
        const PF = parentFolder.data as FolderContent;
        console.log(PF, 'this is pf');
        if (PF.path !== null) {
          const parentFolderPath = PF.path;
          console.log(PF.path, 'this is pf path');
          newPath = `${parentFolderPath}/${folderName}`; // Construct the new folder's path
        } else {
          console.error('Parent folder does not exist.');
          setLoading(false);
          return;
        }
      } else {
        // If there's no parent ID, this folder is a root folder
        newPath = `/${folderName}`;
      }

      // Add the new folder to Firestore
      const sendFolder = await axios.post(
        '/api/fileStructure/createNewFolder',
        { id: docID, name: folderName, parentID: parentFolderId, path: newPath }
      );
      const data = sendFolder.data as FolderContent;
      // Optionally, fetch the updated list of folders or update the local state directly
      // For simplicity, we're directly updating the local state here
      const newFolder = {
        id: docID,
        name: folderName,
        parentID: parentFolderId,
        path: newPath,
        uid: data.uid,
        time: data.time,
        lastEdit: data.time,
      };
      if (parentFolderId && parentFolderId !== null) {
        const updatedHistory = folderHistory.map((entry) => {
          if (entry.folder.id === parentFolderId) {
            return {
              ...entry,
              subFolders: Array.isArray(entry.subFolders)
                ? [...entry.subFolders, newFolder]
                : [newFolder],
            };
          }
          // Return unmodified content
          return entry;
        });
        setFolderHistory(updatedHistory);
      }
      await mutateFolderContentSWR();
      return newFolder as FolderContent;
    } catch (error) {
      console.error('Error creating new folder:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFolderOrContent = async (itemId: string, isFolder: boolean) => {
    setLoading(true);
    try {
      // Assuming you have separate collections for folders and content
      if (isFolder) {
        await axios.post('/api/fileStructure/deleteFolder', itemId);
      } else {
        await axios.post('/api/fileStructure/removeFileFromFolder', itemId);
      }

      // Update folderHistory to reflect deletion
      // This simplistic approach assumes you'll refresh the folder's content
      // A more complex approach might involve directly manipulating the state to remove the item
      const updatedHistory = folderHistory.map((entry) => {
        if (isFolder) {
          // Remove the deleted folder and its subfolders from the history
          return {
            ...entry,
            subFolders: entry.subFolders.filter(
              (folder) => folder.id !== itemId
            ),
          };
        } else {
          // Remove the deleted content from the history
          return {
            ...entry,
            contents: entry.contents.filter((content) => content.id !== itemId),
          };
        }
      });
      setFolderHistory(updatedHistory);
      await mutateFolderContentSWR();
    } catch (error) {
      console.error('Error deleting folder/content:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateContent = async (
    content: string,
    contentID: string,
    folderID?: string
  ) => {
    setLoading(true);
    try {
      await axios.post<ContentResponsesOutput>(
        '/api/fileStructure/updateContent',
        { content, contentID, folderID }
      );
      const updatedHistory = folderHistory.map((entry) => {
        // Map through the contents and update the specified content
        const updatedContents = entry.contents.map((contentResponse) => {
          if (contentResponse.id === contentID) {
            // Found the content, update its response
            return {
              ...contentResponse,
              response: content,
              time: moment().unix(),
            };
          }
          // Return unmodified content
          return contentResponse;
        });

        // Return the updated entry
        return {
          ...entry,
          contents: updatedContents,
        };
      });
      // return updatedHistory;
      setFolderHistory(updatedHistory);
      await mutateFolderContentSWR();
    } catch (error) {
      console.error('Error deleting folder/content:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveFileToFolder = async (folderID: string, contentID: string) => {
    setLoading(true);
    try {
      const saveToFolder = await axios.post(
        '/api/fileStructure/saveFileToFolder',
        { folderID: folderID, contentID: contentID }
      );
      await mutateFolderContentSWR();
    } catch (error) {
      console.error('Error saving to the folder:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveSuperChatToFolder = async (
    folderID: string,
    response: string,
    brand: string,
    audience: string
  ) => {
    setLoading(true);
    try {
      const saveToFolder = await axios.post(
        '/api/fileStructure/saveSuperChatToFolder',
        { folderID, response, brand, audience }
      );
      await mutateFolderContentSWR();
    } catch (error) {
      console.error('Error saving to the folder:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FolderContext.Provider
      value={{
        folders,
        activeFolderId,
        setActiveFolderId,
        folderContentError,
        folderContentSWR,
        mutateFolderSWR,
        loadMoreFolders,
        canLoadMoreFolders,
        setCanLoadMoreFolders,
        loading,
        setLoading,
        navigateToPathIndex,
        navigateToFolder,
        folderHistory,
        setFolderHistory,
        createFolder,
        mostRecentFolder,
        setMostRecentFolder,
        saveFileToFolder,
        saveSuperChatToFolder,
        updateContent,
        deleteFolderOrContent,
        functionStillLoading,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};

export const useFolderContext = (): FolderContentContextInterface => {
  const context = useContext(FolderContext);
  if (context === undefined) {
    throw new Error('FolderContext must be within FolderContextProvider');
  }

  return context;
};
