import { ReactNode } from 'react';

import styles from './BasicPopup.module.css';

type BasicPopupProps = {
  children: ReactNode;
  id: string;
};

export const BasicPopup = ({ children, id }: BasicPopupProps) => {
  return (
    <div className={styles['container']}>
      <div id={id} className={styles['inner-container']}>
        <div className={styles['card']}>{children}</div>
      </div>
    </div>
  );
};

export const BasicPopupZ = ({ children, id }: BasicPopupProps) => {
  return (
    <div className={styles['container-z']}>
      <div id={id} className={styles['inner-container']}>
        <div className={styles['card']}>{children}</div>
      </div>
    </div>
  );
};

export const BasicPopup2 = ({ children, id }: BasicPopupProps) => {
  return (
    <div className={styles['container']}>
      <div id={id} className={styles['inner-container']}>
        <div className={styles['card2']}>{children}</div>
      </div>
    </div>
  );
};

export const BasicPopup50vw = ({ children, id }: BasicPopupProps) => {
  return (
    <div className={styles['container']}>
      <div id={id} className={styles['inner-container']}>
        <div className={styles['card50vw']}>{children}</div>
      </div>
    </div>
  );
};
