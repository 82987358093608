import { FC } from 'react';

type SuperChatSVGProps = {
  stroke?: string;
};

export const SuperChatSVG: FC<SuperChatSVGProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.00049 1C2.34363 1 1.00049 2.34315 1.00049 4V6C1.00049 6.12017 1.00755 6.2387 1.02129 6.35518C1.00744 6.40102 1 6.44964 1 6.5V10.9129C1 11.3401 1.50106 11.5705 1.8254 11.2925L3.93827 9.48149C4.30075 9.17078 4.76243 9 5.23985 9H10.0005C11.6573 9 13.0005 7.65685 13.0005 6V4C13.0005 2.34315 11.6573 1 10.0005 1H4.00049Z" fill="#62D9FF" stroke="#383838"/>
</svg>


  )
  }