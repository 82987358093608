import { FC } from 'react';

type CampaignsSVGProps = {
  stroke?: string;
};

export const CampaignsSVG: FC<CampaignsSVGProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 2.5L4 0.5L8 2.5L11.5 0.5V9.5L8 11.5L4 9.5L0.5 11.5V2.5Z" fill="#FF7C62" stroke="#14142B" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 2.5V11.25" stroke="#14142B"/>
<path d="M4 0.75V9.5" stroke="#14142B"/>
</svg>



  )
  }