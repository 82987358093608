import { FC } from 'react';

type NewBlogSvgProps = {
  stroke?: string;
};

export const NewBlogSvg: FC<NewBlogSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33366 12.8332C10.5003 12.8332 10.5003 11.6665 10.5003 11.6665V2.33317C10.5003 2.33317 10.5003 1.1665 11.667 1.1665C12.8337 1.1665 12.8337 2.33317 12.8337 2.33317V4.08317H10.5003M9.33366 12.8332C8.16699 12.8332 8.16699 11.6665 8.16699 11.6665V9.9165H1.16699V11.6665C1.16699 11.6665 1.16699 12.8332 2.33366 12.8332C3.50033 12.8332 8.16699 12.8332 9.33366 12.8332Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.91699 9.9165V2.33317C2.91699 2.33317 2.91699 1.1665 4.08366 1.1665C5.25033 1.1665 11.667 1.1665 11.667 1.1665" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.25 4.6665H8.16667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.25 7H8.16667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
</svg>





  );
};
