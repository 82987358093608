import axios from 'axios';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { AlphaUser, GroupedUserGeneratedResponses } from '../types/API';
import { useAuthContext } from './AuthContext';

interface AdminContextInterface {
  isAdmin: boolean;
  allAlphaUsers: AlphaUser[] | undefined;
  allUserGeneratedResponses: GroupedUserGeneratedResponses | undefined;
  mutateAllAlphaUsersSWR: KeyedMutator<AlphaUser[]>;
}

const AdminContext = createContext<AdminContextInterface | undefined>(
  undefined
);

export const AdminContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const { authUser } = useAuthContext();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [allAlphaUsers, setAllAlphaUsers] = useState<AlphaUser[] | undefined>();
  const [allUserGeneratedResponses, setAllUserGeneratedResponses] = useState<
    GroupedUserGeneratedResponses | undefined
  >();

  const {
    data: allAlphaUsersSWR,
    mutate: mutateAllAlphaUsersSWR,
    error: allAlphaUsersError,
  } = useSWR<AlphaUser[], Error>(
    authUser && authUser.uid ? '/api/auth/getAlphaUsers' : null
  );

  const getAllUserGeneratedResponses = async () => {
    try {
      const { data: axiosUserGeneratedResponsesData } =
        await axios.post<GroupedUserGeneratedResponses>(
          '/api/auth/getUserGeneratedResponses'
        );
      setAllUserGeneratedResponses(axiosUserGeneratedResponsesData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (allAlphaUsersSWR) {
      // // Fetch generated responses on first load
      // if (!allAlphaUsers) {
      //   getAllUserGeneratedResponses();
      // }
      setAllAlphaUsers(allAlphaUsersSWR);
    }
  }, [allAlphaUsersSWR]);

  useEffect(() => {
    if (authUser) {
      if (authUser.uid === 'qaYDDwZDBQMM8e0pvEuvUlFhmCw2') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        router.push('/');
      }
    }
  }, [authUser]);

  return (
    <AdminContext.Provider
      value={{
        isAdmin,
        allAlphaUsers,
        allUserGeneratedResponses,
        mutateAllAlphaUsersSWR,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = (): AdminContextInterface => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error('AdminContext must be within AdminContextProvider');
  }

  return context;
};
