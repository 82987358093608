import { FC } from 'react';

type ContentConciserSvgProps = {
  stroke?: string;
};

export const ContentConciserSvg: FC<ContentConciserSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.62305 7.91461L6.38561 6.67718" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.7476 12.0394L10.9226 11.2145" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.1914 10.1832L10.3041 10.5957L9.89157 13.4831L7.4167 11.0082L7.82918 8.12083L10.7165 7.70835L13.1914 10.1832Z" stroke={stroke}/>
<path d="M9.10134 5.61137C8.95909 5.24836 8.74131 4.90812 8.44799 4.6148C7.30896 3.47577 5.46223 3.47577 4.3232 4.6148C3.18417 5.75383 3.18417 7.60056 4.3232 8.73959C4.61652 9.03291 4.95676 9.25069 5.31977 9.39294" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.3617 5.00063C11.1117 4.25661 10.6903 3.5575 10.0976 2.96483C8.04739 0.914577 4.72327 0.914577 2.67302 2.96483C0.622769 5.01508 0.622769 8.3392 2.67302 10.3895C3.26569 10.9821 3.9648 11.4035 4.70882 11.6535" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
</svg>






  );
};
