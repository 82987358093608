import { FC } from 'react';

type TemplatesSVGProps = {
  stroke?: string;
};

export const TemplatesSVG: FC<TemplatesSVGProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="1.5" width="10" height="3" fill="#6285FF" stroke="#383838"/>
<rect x="1" y="7.5" width="10" height="3" fill="#6285FF" stroke="#383838"/>
</svg>

  )
  }