import { FC } from 'react';

type ShowMoreSvgProps = {
  stroke?: string;
};

export const ShowMoreSvg: FC<ShowMoreSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9258 10L11.9626 16.9632L4.99944 10" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



  );
};




