import { FC } from 'react';

type PersonasSVGProps = {
  stroke?: string;
};

export const PersonasSVG: FC<PersonasSVGProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="7" cy="7" rx="5.5" ry="5.5" fill="#FFEF62" stroke="#383838"/>
<path d="M5 8.5C5 8.5 5.85714 9.24924 7 9.24924C8.14286 9.24924 9 8.5 9 8.5" stroke="#383838" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 5.5H5.125" stroke="#383838" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 5.5H9.125" stroke="#383838" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
  }