import { FC } from 'react';

type ToneChangerSvgProps = {
  stroke?: string;
};

export const ToneChangerSvg: FC<ToneChangerSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.8335 7.63936H5.16683L9.3335 4.30603V15.9727L5.16683 12.6394H1.8335V7.63936Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4182 3.33337C18.2694 4.18458 18.9446 5.1951 19.4053 6.30725C19.8659 7.41941 20.103 8.6114 20.103 9.81519C20.103 11.019 19.8659 12.211 19.4053 13.3231C18.9446 14.4353 18.2694 15.4458 17.4182 16.297" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
<path d="M13.8627 6.50269C14.327 6.96698 14.6953 7.51817 14.9465 8.1248C15.1978 8.73143 15.3271 9.38161 15.3271 10.0382C15.3271 10.6948 15.1978 11.345 14.9465 11.9516C14.6953 12.5583 14.327 13.1095 13.8627 13.5738" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
</svg>





  );
};
