import { FC } from 'react';

type ContentExpanderSvgProps = {
  stroke?: string;
};

export const ContentExpanderSvg: FC<ContentExpanderSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.21794 2.1266L11.8782 2.1266L11.8782 5.79626" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.667 2.33331L8.16686 5.83331" stroke={stroke} strokeLinecap="round"/>
<path d="M5.78206 11.8734L2.12183 11.8734L2.12183 8.20375" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.8335 8.17053L2.33336 11.6705" stroke={stroke} strokeLinecap="round"/>
</svg>







  );
};
