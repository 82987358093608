import { FC } from 'react';

type ContentSVGProps = {
  stroke?: string;
};

export const ContentSVG: FC<ContentSVGProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 5C0.5 3.34315 1.84315 2 3.5 2H3.64023C4.35636 2 5.04887 2.25618 5.5926 2.72223L5.6574 2.77777C6.20113 3.24382 6.89364 3.5 7.60977 3.5H8.5C10.1569 3.5 11.5 4.84315 11.5 6.5V7.5C11.5 9.15685 10.1569 10.5 8.5 10.5H3.5C1.84315 10.5 0.5 9.15685 0.5 7.5V5Z" fill="white" stroke="#383838"/>
</svg>

  )
  }