import { FC } from 'react';

type EmailSvgProps = {
  stroke?: string;
};

export const EmailSvg: FC<EmailSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 4.33338C1 3.22881 1.89543 2.33337 3 2.33337H13C14.1046 2.33337 15 3.2288 15 4.33337V10.25C15 11.3546 14.1046 12.25 13 12.25H3C1.89543 12.25 1 11.3546 1 10.25V4.33338Z" stroke={stroke} strokeLinejoin="round"/>
<path d="M2.42131 3.63624C1.91709 3.17404 2.24409 2.33337 2.9281 2.33337H13.0719C13.7559 2.33337 14.0829 3.17404 13.5787 3.63624L10.0272 6.8918C8.88021 7.94318 7.11979 7.94318 5.97283 6.8918L2.42131 3.63624Z" stroke={stroke} strokeLinejoin="round"/>
</svg>



  );
};
