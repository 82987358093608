import { FC } from 'react';

type AudienceChangerSvgProps = {
  stroke?: string;
};

export const AudienceChangerSvg: FC<AudienceChangerSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (

<svg width="14" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_132_1500)">
<circle cx="5.8335" cy="5" r="2.5" stroke={stroke} strokeWidth="2"/>
<ellipse cx="13.7498" cy="7.08333" rx="2.08333" ry="2.08333" stroke={stroke} strokeWidth="2"/>
<path d="M0.833496 15C0.833496 12.2386 3.07207 10 5.8335 10V10C8.59492 10 10.8335 12.2386 10.8335 15V16.6667H0.833496V15Z" stroke={stroke} strokeWidth="2"/>
<path d="M10.448 13.0715L9.52552 13.4575L9.27189 12.8513L9.72764 12.3779L10.448 13.0715ZM10.8335 16.6667L10.8335 17.6667L9.8335 17.6667V16.6667H10.8335ZM11.1311 16.6667L11.1311 17.6667L11.1311 17.6667L11.1311 16.6667ZM18.3335 16.6667H19.3335V17.6667H18.3335V16.6667ZM9.8335 15C9.8335 14.4513 9.72358 13.9308 9.52552 13.4575L11.3705 12.6855C11.6691 13.399 11.8335 14.1816 11.8335 15H9.8335ZM9.8335 16.6667V15H11.8335V16.6667H9.8335ZM10.8335 15.6667L11.1311 15.6667L11.1311 17.6667L10.8335 17.6667L10.8335 15.6667ZM11.1311 15.6667H18.3335V17.6667H11.1311V15.6667ZM17.3335 16.6667V16.25H19.3335V16.6667H17.3335ZM17.3335 16.25C17.3335 14.271 15.7292 12.6667 13.7502 12.6667V10.6667C16.8338 10.6667 19.3335 13.1664 19.3335 16.25H17.3335ZM13.7502 12.6667C12.7359 12.6667 11.8215 13.0867 11.1684 13.7651L9.72764 12.3779C10.7421 11.3242 12.1702 10.6667 13.7502 10.6667V12.6667Z" fill={stroke}/>
</g>
<defs>
<clipPath id="clip0_132_1500">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>







  );
};
