import { FC } from 'react';

type SearchSvgProps = {
  stroke?: string;
};

export const SearchSvg: FC<SearchSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.41699 11.6667C9.31649 11.6667 11.667 9.31624 11.667 6.41675C11.667 3.51725 9.31649 1.16675 6.41699 1.16675C3.5175 1.16675 1.16699 3.51725 1.16699 6.41675C1.16699 9.31624 3.5175 11.6667 6.41699 11.6667Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.25 12.25L10.5 10.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
</svg>






  );
};
