import {
  suggestedDemographics,
  suggestedTones,
} from '../components/pages/editorPage/forms/formInputs/DefaultOptions';
import { AllPlatform, FormType, SocialPlatform } from './FormTypes';
import { NextImageBasicData } from './Next';
import { string } from 'yup';

export interface Campaign {
  id: string;
  authorUid: string;
  campaignTemplateId?: string;
  title: string;
  companyName: string;
  companyWebsite: string;
  mainGoal: string;
  platform: AllPlatform;
  intent: string;
  tone: string[];
  targetDemographic: string[];
  duration: CampaignDuration;
  tasks: CampaignTask[];
  createdOn: number;
  updatedOn: number;
}

// export interface CampaignOutline {
//   content: any;
//   contentType: string,
//   subscription: string,
//   authorUID: string,
//   id: string,
//   profilePic: string,
//   brandPersona: string,
//   audPersona: string,
// }

export enum CampaignDuration {
  '7DAYS' = '7 Days',
  '30DAYS' = '30 Days',
  '45DAYS' = '45 Days',
}

// export const CampaignPlatformTask: Record<AllPlatform, string> = {
//   [AllPlatform.FACEBOOK]: 'Facebook post',
//   [AllPlatform.INSTAGRAM]: 'Instagram post',
//   [AllPlatform.TWITTER]: 'tweet',
//   [AllPlatform.LINKEDIN]: 'LinkedIn post',
//   [AllPlatform.EMAIL]: 'email blast',
//   [AllPlatform.BLOG]: 'blog',
// };

export interface CampaignTask {
  index: number;
  date: number;
  completed: boolean;
  skipped: boolean;
  prompt: string;
}

export enum CampaignTaskStatus {
  'PAST' = 'past',
  'UPCOMING' = 'upcoming',
  'COMPLETED' = 'completed',
  'SKIPPED' = 'skipped',
}

export enum CampaignTemplateIds {
  'LINKEDINGENERATE30' = 'linkedInGenerate30',
}

export const CampaignTemplateNames: { [campaignId: string]: string } = {
  [CampaignTemplateIds.LINKEDINGENERATE30]: '30-Day LinkedIn Challenge',
};

export const CampaignTemplates: {
  [campaignId: string]: Partial<Campaign>;
} = {
  [CampaignTemplateIds.LINKEDINGENERATE30]: {
    duration: CampaignDuration['30DAYS'],
    mainGoal: 'Generate New Audience',
    platform: AllPlatform.LINKEDIN,
    intent:
      'Gain followers and connections for your product/brand through Linkedin.',
    tone: [suggestedTones[FormType.SOCIAL][SocialPlatform.LINKEDIN]],
    targetDemographic: [
      suggestedDemographics[FormType.SOCIAL][SocialPlatform.LINKEDIN],
    ],
  },
};

export enum DefaultCampaignMainGoals {
  NURTURE = 'Nurture Current Audience',
  GENERATE = 'Generate New Audience',
  SELL = 'Sell a Product or Service',
}

export const DefaultCampaignMainGoalIcons: Record<
  DefaultCampaignMainGoals | string,
  NextImageBasicData
> = {
  [DefaultCampaignMainGoals.NURTURE]: {
    src: '/icons/GageBlack.svg',
    height: 16,
    width: 16,
  },
  [DefaultCampaignMainGoals.GENERATE]: {
    src: '/icons/TargetArrowBlack.svg',
    height: 16,
    width: 16,
  },
  [DefaultCampaignMainGoals.SELL]: {
    src: '/icons/GageBlack.svg',
    height: 16,
    width: 16,
  },
};

// ---API---
export interface CampaignTaskManipulationInput {
  campaignId: string;
  taskIndex: number;
  taskManipulationType: 'complete' | 'skip';
}


export interface CampaignItem {
  Title: string;
  Description: string;
  'Marketing Channel': string;
  'Which Week': string;
  content: string;
  stage: number;
  lastEdit: string;
  isEditing: boolean;
}

export interface CampaignOutline {
  title: string,
  brief?: string,
  content: CampaignItem[];
  contentType: string,
  subscription: string,
  authorUID: string,
  id: string,
  profilePic: string,
  brandPersona: string,
  brandPic?: string,
  audPersona: string,
  lastEdit: string,
  audPic?: string,
  marketingChannels?: string[],
}


export type Campaigns = CampaignOutline[];