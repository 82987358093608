import { FC } from 'react';

type CompassSvgProps = {
  stroke?: string;
};

export const CompassSvg: FC<CompassSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5Z" stroke={stroke}/>
<path d="M6.2168 6.2168L9.83394 4.96329L8.58043 8.58044L4.96329 9.83394L6.2168 6.2168Z" stroke={stroke}/>
</svg>

  );
};
