import { FC } from 'react';

type LinkedInSvgProps = {
  stroke?: string;
};

export const LinkedInSvg: FC<LinkedInSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_224_3988)">
<path d="M12.9637 0H1.03359C0.462109 0 0 0.451172 0 1.00898V12.9883C0 13.5461 0.462109 14 1.03359 14H12.9637C13.5352 14 14 13.5461 14 12.991V1.00898C14 0.451172 13.5352 0 12.9637 0ZM4.15352 11.9301H2.07539V5.24727H4.15352V11.9301ZM3.11445 4.33672C2.44727 4.33672 1.90859 3.79805 1.90859 3.13359C1.90859 2.46914 2.44727 1.93047 3.11445 1.93047C3.77891 1.93047 4.31758 2.46914 4.31758 3.13359C4.31758 3.79531 3.77891 4.33672 3.11445 4.33672ZM11.9301 11.9301H9.85469V8.68164C9.85469 7.90781 9.84101 6.90977 8.77461 6.90977C7.69453 6.90977 7.53047 7.75469 7.53047 8.62695V11.9301H5.45781V5.24727H7.44844V6.16055H7.47578C7.75195 5.63555 8.43008 5.08047 9.43906 5.08047C11.5418 5.08047 11.9301 6.46406 11.9301 8.26328V11.9301V11.9301Z" fill={stroke}/>
</g>
<defs>
<clipPath id="clip0_224_3988">
<rect width="14" height="14" fill={stroke}/>
</clipPath>
</defs>
</svg>

  );
};
