import { FC } from 'react';

type CampaignBrainstormSvgProps = {
  stroke?: string;
};

export const CampaignBrainstormSvg: FC<CampaignBrainstormSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.875 8.16671L3.20835 5.54171C3.20835 5.54171 2.92179 4.92802 2.92179 4.08337C2.92179 2.15038 4.63125 0.583374 6.73998 0.583374C8.15324 0.583374 9.38717 1.28722 10.0474 2.33337H10.7968C12.2465 2.33337 13.4218 3.50863 13.4218 4.95837C13.4218 5.8707 12.8333 7.00004 12.25 7.7281C11.6667 8.45615 8.16667 12.5417 8.16667 12.5417" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.83931 6.58817L9.46436 7.03503" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.80221 11.6172L1.42725 12.0641" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.46839 5.08827L3.88538 9.24648L6.79769 9.40574L3.49967 13.1251" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
</svg>





  );
};
