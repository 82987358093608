import axios, { AxiosError } from 'axios';
import { FormikErrors, FormikHelpers, FormikValues } from 'formik';
import moment from 'moment';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { string } from 'yup';
import {
  suggestedDemographics,
  suggestedTones,
} from '../components/pages/editorPage/forms/formInputs/DefaultOptions';
import { APIErrors, CampaignResponsesOutput } from '../types/API';
import {
  Campaign,
  CampaignDuration,
  CampaignItem,
  CampaignOutline,
  CampaignTaskManipulationInput,
  CampaignTemplateNames,
  Campaigns,
} from '../types/Campaign.type';
import { AllPlatform, FormType, SocialPlatform } from '../types/FormTypes';
import { useAuthContext } from './AuthContext';
import { usePersonaContext } from './PersonaContext';
import mixpanel from 'mixpanel-browser';

interface CampaignContextInterface {
  campaigns: Campaigns | undefined;

  //   campaignInput: Campaign | undefined;
  //   setCampaignInput: Dispatch<SetStateAction<Campaign | undefined>>;
  mutateCampaignSWR: () => Promise<void>;
  updateCampaign: (
    C: CampaignItem[],
    CT: string,
    PP: string,
    AP: string,
    BP: string,
    title: string,
    lastEdit: Date,
    id: string,
    brief?: string,
    brandPic?: string,
    audPic?: string,
    MC?: string[]
  ) => Promise<void>;
  loadMoreCampaigns: () => Promise<void>;
  canLoadMoreCampaigns: boolean;
  loadingMoreCampaigns: boolean;
  setCanLoadMoreCampaigns: Dispatch<SetStateAction<boolean>>;

  deleteCampaign: () => Promise<void>;

  loading: boolean;
  generalErrors: string;
}

const CampaignNewContext = createContext<CampaignContextInterface | undefined>(
  undefined
);

const defaultCampaignInput: Campaign = {
  id: '',
  authorUid: '',
  title: '',
  companyName: '',
  companyWebsite: '',
  mainGoal: 'Nurture Current Audience',
  platform: AllPlatform.TWITTER,
  intent: '',
  tone: [suggestedTones[FormType.SOCIAL][SocialPlatform.TWITTER]],
  targetDemographic: [
    suggestedDemographics[FormType.SOCIAL][SocialPlatform.TWITTER],
  ],
  duration: CampaignDuration['30DAYS'],
  tasks: [],
  createdOn: 0,
  updatedOn: 0,
};

export const CampaignNewContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const { query } = router;
  const campaignID = query.id as string;
  const queryCampaignId = router.query.campaignId;
  const queryCampaignTemplateId = router.query.campaignTemplateId;
  const [campaigns, setCampaigns] = useState<Campaigns | undefined>();
  const [canLoadMoreCampaigns, setCanLoadMoreCampaigns] =
    useState<boolean>(false);
  const [loadingMoreCampaigns, setLoadingMoreCampaigns] =
    useState<boolean>(false);

  const { refreshAllContentTypes } = usePersonaContext();
  const { isSignedIn } = useAuthContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [generalErrors, setGeneralErrors] = useState<string>('');

  const { data: campaignsNewSWR, mutate: mutateCampaignsSWR } = useSWR<
    CampaignResponsesOutput,
    Error
  >(isSignedIn ? '/api/openai/campaign/retrieveCampaigns' : null);

  useEffect(() => {
    if (campaignsNewSWR) {
      setCampaigns(campaignsNewSWR.campaignResponses);
      setCanLoadMoreCampaigns(campaignsNewSWR.canLoadMore);
    }
  }, [campaignsNewSWR]);

  const updateCampaign = async (
    C: CampaignItem[],
    CT: string,
    PP: string,
    AP: string,
    BP: string,
    title: string,
    lastEdit: Date,
    id: string,
    brief?: string,
    brandPic?: string,
    audPic?: string,
    MC?: string[]
  ) => {
    try {
      setLoading(true);
      await axios.post('/api/campaigns/updateCampaign', {
        C,
        CT,
        PP,
        AP,
        BP,
        title,
        lastEdit,
        id,
        brief,
        brandPic,
        audPic,
        MC,
      });
      mutateCampaignsSWR();
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await mutateCampaignSWR();
      console.log('Campaigns has been updated!');
    };
    fetchData();
  }, [refreshAllContentTypes]);

  const deleteCampaign = async () => {
    setLoading(true);
    setGeneralErrors('');
    try {
      if (campaignID) {
        await axios.delete('/api/campaigns/deleteCampaignUpdated', {
          data: {
            campaignId: campaignID,
          },
        });
      }
      await mutateCampaignsSWR();
      //   await router.push('/campaigns');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  const mutateCampaignSWR = async () => {
    try {
      await mutateCampaignsSWR();
      console.log('Campaign saved and updated');
    } catch (error) {
      const generalErr = error as APIErrors;
      setGeneralErrors(
        generalErr.error || 'Something went wrong, please try again'
      );
    }
  };

  const loadMoreCampaigns = async () => {
    if (!campaigns) return;
    setLoading(true);
    setLoadingMoreCampaigns(true);
    try {
      const { data: nextCampaignResponses } =
        await axios.get<CampaignResponsesOutput>(
          `/api/openai/campaign/retrieveCampaigns?startAfterId=${
            campaigns[campaigns.length - 1].id
          }`
        );
      const newCampaignResponses = [
        ...campaigns,
        ...nextCampaignResponses.campaignResponses,
      ];
      setCampaigns(newCampaignResponses);
      setCanLoadMoreCampaigns(nextCampaignResponses.canLoadMore);
      mutateCampaignSWR();
      mixpanel.track('Load more campaign responses', {
        totalNumResponsesLoaded: newCampaignResponses.length,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingMoreCampaigns(false);
    }
  };

  return (
    <CampaignNewContext.Provider
      value={{
        campaigns,
        mutateCampaignSWR,

        // campaignInput,
        // setCampaignInput,

        updateCampaign,
        deleteCampaign,
        loadMoreCampaigns,
        loadingMoreCampaigns,
        canLoadMoreCampaigns,
        setCanLoadMoreCampaigns,

        loading,
        generalErrors,
      }}
    >
      {children}
    </CampaignNewContext.Provider>
  );
};

export const useNewCampaignContext = (): CampaignContextInterface => {
  const context = useContext(CampaignNewContext);
  if (context === undefined) {
    throw new Error('CampaignContext must be within CampaignContextProvider');
  }

  return context;
};
