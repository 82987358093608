// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAG7q_hESytKdGa1G6JrHEh69oii1ZSrDg',
  authDomain: 'supercopy-8ae17.firebaseapp.com',
  projectId: 'supercopy-8ae17',
  storageBucket: 'supercopy-8ae17.appspot.com',
  messagingSenderId: '902282593744',
  appId: '1:902282593744:web:b4a3734ec0fec5dbbbe5d5',
  measurementId: 'G-RS218595BB',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
//this is for setting up the storage to save files and images.
const storage = getStorage(firebaseApp);

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: 'select_account',
});
const signInWithGooglePopup = () =>
  signInWithPopup(firebaseAuth, googleAuthProvider);

const sendEmailLinkSignIn = (email: string) =>
  sendSignInLinkToEmail(firebaseAuth, email, {
    // Conditionally set the URL based on the environment
    url:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/signin/finishEmailLink' // URL for development environment
        : 'https://supercopy.ai/signin/finishEmailLink', // URL for production environment
    handleCodeInApp: true,
  })
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error: { code: string; message: string }) => {
      console.log(error.code, error.message);
    });

const signInEmailPassword = (email: string, password: string) =>
  signInWithEmailAndPassword(firebaseAuth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {});

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  // Optional "tenant" parameter in case you are using an Azure AD tenant.
  // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
  // or "common" for tenant-independent tokens.
  // // The default value is "common".
  // tenant: '3ba32825-d5d7-4035-abb7-2a1f5280f933'
});

const signInWithMicrosoftPopup = () =>
  signInWithPopup(firebaseAuth, microsoftProvider);
// .then((result) => {
//   // User is signed in.
//   // IdP data available in result.additionalUserInfo.profile.

//   // Get the OAuth access token and ID Token
//   const credential = OAuthProvider.credentialFromResult(result);
//   const accessToken = credential?.accessToken;
//   const idToken = credential?.idToken;
// })
// .catch((error) => {
//   // Handle error.
// });

const isValidEmailSignInLink = (url: string) =>
  isSignInWithEmailLink(firebaseAuth, url);

const signInWithEmailLink = (email: string, url: string) =>
  firebaseSignInWithEmailLink(firebaseAuth, email, url);

export {
  firebaseAuth,
  storage,
  signInWithGooglePopup,
  signInWithMicrosoftPopup,
  sendEmailLinkSignIn,
  isValidEmailSignInLink,
  signInWithEmailLink,
  signInEmailPassword,
};
