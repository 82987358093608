export enum SubscriptionType {
  'PERSONAL' = 'PERSONAL',
  'SMALL_BUSINESS' = 'SMALL_BUSINESS',
  'BASIC' = 'BASIC',
  'FREE_TRIAL' = 'FREE_TRIAL',
  'LIMITED' = 'LIMITED',
  'PROFESSIONAL' = 'PROFESSIONAL',
  'COX_MEDIA_PILOT' = 'COX_MEDIA_PILOT',
  'TIER1' = 'TIER1',
  'TIER2' = 'TIER2',
  'TIER3' = 'TIER3',
  'TIER1_2' = 'TIER1_2',
  'TIER2_2' = 'TIER2_2',
  'TIER3_2' = 'TIER3_2',
  'TIER4_2' = 'TIER4_2',
  'SOLO' = 'SOLO',
  'TEAM' = 'TEAM',
  'SMALL_AGENCY' = 'SMALL_AGENCY',
}

export enum SubscriptionLength {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  FREE_TRIAL = 'free trial',
  APPSUMO = 'appsumo',
  PILOT = 'pilot',
}

type SubscriptionPriceIds = Record<
  SubscriptionType,
  Record<SubscriptionLength, string>
>;

type SubscriptionPriceIdsLookup = Record<
  string,
  {
    subscriptionType: SubscriptionType;
    subscriptionLength: SubscriptionLength;
  }
>;

const devSubscriptionIds: SubscriptionPriceIds = {
  [SubscriptionType.PERSONAL]: {
    [SubscriptionLength.MONTHLY]: 'price_1LQByJEdQRMVd2yEcDY5RlEQ',
    [SubscriptionLength.YEARLY]: 'price_1LW29qEdQRMVd2yEUvL4f2qe',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.BASIC]: {
    [SubscriptionLength.MONTHLY]: 'price_12345567',
    [SubscriptionLength.YEARLY]: 'price_12344564466',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.FREE_TRIAL]: {
    [SubscriptionLength.MONTHLY]: 'price_12345567',
    [SubscriptionLength.YEARLY]: 'price_12344564466',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.SMALL_BUSINESS]: {
    [SubscriptionLength.MONTHLY]: 'price_1LQByxEdQRMVd2yE0edBJcCn',
    [SubscriptionLength.YEARLY]: 'price_1LW2C7EdQRMVd2yE2NFlCCJo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.LIMITED]: {
    [SubscriptionLength.MONTHLY]: 'price_1MbrChEdQRMVd2yEMRMPe5TL',
    [SubscriptionLength.YEARLY]: 'price_1MbrFrEdQRMVd2yEcp4PSNym',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.PROFESSIONAL]: {
    [SubscriptionLength.MONTHLY]: 'price_1MbrGwEdQRMVd2yEfZgJnjri',
    [SubscriptionLength.YEARLY]: 'price_1MbrGwEdQRMVd2yEXS0tYk59',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER1]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER3]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER1_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER2_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER3_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER4_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.COX_MEDIA_PILOT]: {
    [SubscriptionLength.MONTHLY]: 'coxpilot',
    [SubscriptionLength.YEARLY]: 'coxpilot',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  //these were the old ones before we had created the basic and pro plan
  // now the solo will represent the basic plan and same for team
  // [SubscriptionType.SOLO]: {
  //   [SubscriptionLength.MONTHLY]: 'price_1OgzQIEdQRMVd2yEVWIkxJtp',
  //   [SubscriptionLength.YEARLY]: 'price_1OgzQIEdQRMVd2yE4QKDX5Rl',
  //   [SubscriptionLength.FREE_TRIAL]: 'freetrial',
  //   [SubscriptionLength.APPSUMO]: 'appsumo',
  //   [SubscriptionLength.PILOT]: 'pilot',
  // },

  // [SubscriptionType.TEAM]: {
  //   [SubscriptionLength.MONTHLY]: 'price_1OgzRNEdQRMVd2yEHTP4bFfx',
  //   [SubscriptionLength.YEARLY]: 'price_1OgzS3EdQRMVd2yESU4L3bHb',
  //   [SubscriptionLength.FREE_TRIAL]: 'freetrial',
  //   [SubscriptionLength.APPSUMO]: 'appsumo',
  //   [SubscriptionLength.PILOT]: 'pilot',
  // },

  [SubscriptionType.SOLO]: {
    [SubscriptionLength.MONTHLY]: 'price_1OgzQIEdQRMVd2yEVWIkxJtp',
    [SubscriptionLength.YEARLY]: 'price_1OgzQIEdQRMVd2yE4QKDX5Rl',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },

  [SubscriptionType.TEAM]: {
    [SubscriptionLength.MONTHLY]: 'price_1OgzRNEdQRMVd2yEHTP4bFfx',
    [SubscriptionLength.YEARLY]: 'price_1OgzS3EdQRMVd2yESU4L3bHb',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },

  [SubscriptionType.SMALL_AGENCY]: {
    [SubscriptionLength.MONTHLY]: 'coxpilot',
    [SubscriptionLength.YEARLY]: 'coxpilot',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
};

const devSubscriptionIdsLookup: SubscriptionPriceIdsLookup = {
  price_1LQByJEdQRMVd2yEcDY5RlEQ: {
    subscriptionType: SubscriptionType.PERSONAL,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1LW29qEdQRMVd2yEUvL4f2qe: {
    subscriptionType: SubscriptionType.PERSONAL,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1LQByxEdQRMVd2yE0edBJcCn: {
    subscriptionType: SubscriptionType.SMALL_BUSINESS,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1LW2C7EdQRMVd2yE2NFlCCJo: {
    subscriptionType: SubscriptionType.SMALL_BUSINESS,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1MbrChEdQRMVd2yEMRMPe5TL: {
    subscriptionType: SubscriptionType.LIMITED,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1MbrFrEdQRMVd2yEcp4PSNym: {
    subscriptionType: SubscriptionType.LIMITED,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1MbrGwEdQRMVd2yEfZgJnjri: {
    subscriptionType: SubscriptionType.PROFESSIONAL,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1MbrGwEdQRMVd2yEXS0tYk59: {
    subscriptionType: SubscriptionType.PROFESSIONAL,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  // price_1Ns933EdQRMVd2yEbiTjiaQ6: {
  //   subscriptionType: SubscriptionType.SOLO,
  //   subscriptionLength: SubscriptionLength.MONTHLY,
  // },
  // price_1Ns933EdQRMVd2yEDLLsXiaa: {
  //   subscriptionType: SubscriptionType.SOLO,
  //   subscriptionLength: SubscriptionLength.YEARLY,
  // },
  // price_1Ns94BEdQRMVd2yEPcquQ6uz: {
  //   subscriptionType: SubscriptionType.TEAM,
  //   subscriptionLength: SubscriptionLength.MONTHLY,
  // },
  // price_1Ns94BEdQRMVd2yE7yzGWV4u: {
  //   subscriptionType: SubscriptionType.TEAM,
  //   subscriptionLength: SubscriptionLength.YEARLY,
  // },
  price_1OgzQIEdQRMVd2yEVWIkxJtp: {
    subscriptionType: SubscriptionType.SOLO,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1OgzQIEdQRMVd2yE4QKDX5Rl: {
    subscriptionType: SubscriptionType.SOLO,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1OgzRNEdQRMVd2yEHTP4bFfx: {
    subscriptionType: SubscriptionType.TEAM,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1OgzS3EdQRMVd2yESU4L3bHb: {
    subscriptionType: SubscriptionType.TEAM,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
};

const prodSubcriptionIds: SubscriptionPriceIds = {
  [SubscriptionType.PERSONAL]: {
    [SubscriptionLength.MONTHLY]: 'price_1LkUDIEdQRMVd2yE5fEct9SA',
    [SubscriptionLength.YEARLY]: 'price_1LkUDIEdQRMVd2yEkm9HpS3L',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.BASIC]: {
    [SubscriptionLength.MONTHLY]: 'price_123442453',
    [SubscriptionLength.YEARLY]: 'price_11221531351e',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.FREE_TRIAL]: {
    [SubscriptionLength.MONTHLY]: 'price_12345567',
    [SubscriptionLength.YEARLY]: 'price_12344564466',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.SMALL_BUSINESS]: {
    [SubscriptionLength.MONTHLY]: 'price_1LkULMEdQRMVd2yEvmco0yuT',
    [SubscriptionLength.YEARLY]: 'price_1LkULMEdQRMVd2yEbJhLrZ46',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.LIMITED]: {
    [SubscriptionLength.MONTHLY]: 'price_1MbUS2EdQRMVd2yEV2yuWyFR',
    [SubscriptionLength.YEARLY]: 'price_1MbV1DEdQRMVd2yEgDAXgYTc',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.PROFESSIONAL]: {
    [SubscriptionLength.MONTHLY]: 'price_1MbUU9EdQRMVd2yEAzC7UYfW',
    [SubscriptionLength.YEARLY]: 'price_1MbV3EEdQRMVd2yEHERfSfGC',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER1]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER3]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER1_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER2_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER3_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.TIER4_2]: {
    [SubscriptionLength.MONTHLY]: 'demo',
    [SubscriptionLength.YEARLY]: 'demo',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
  [SubscriptionType.COX_MEDIA_PILOT]: {
    [SubscriptionLength.MONTHLY]: 'coxpilot',
    [SubscriptionLength.YEARLY]: 'coxpilot',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },

  [SubscriptionType.SOLO]: {
    [SubscriptionLength.MONTHLY]: 'price_1OlU3NEdQRMVd2yET1mdz5n7',
    [SubscriptionLength.YEARLY]: 'price_1OlU3NEdQRMVd2yE5Pp7p38m',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },

  [SubscriptionType.TEAM]: {
    [SubscriptionLength.MONTHLY]: 'price_1OlU71EdQRMVd2yEQoxUb1VD',
    [SubscriptionLength.YEARLY]: 'price_1OlU7VEdQRMVd2yEH8zKJuu6',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },

  [SubscriptionType.SMALL_AGENCY]: {
    [SubscriptionLength.MONTHLY]: 'coxpilot',
    [SubscriptionLength.YEARLY]: 'coxpilot',
    [SubscriptionLength.FREE_TRIAL]: 'freetrial',
    [SubscriptionLength.APPSUMO]: 'appsumo',
    [SubscriptionLength.PILOT]: 'pilot',
  },
};

const prodSubscriptionIdsLookup: SubscriptionPriceIdsLookup = {
  price_1LkUDIEdQRMVd2yE5fEct9SA: {
    subscriptionType: SubscriptionType.PERSONAL,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1LkUDIEdQRMVd2yEkm9HpS3L: {
    subscriptionType: SubscriptionType.PERSONAL,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1LkULMEdQRMVd2yEvmco0yuT: {
    subscriptionType: SubscriptionType.SMALL_BUSINESS,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1LkULMEdQRMVd2yEbJhLrZ46: {
    subscriptionType: SubscriptionType.SMALL_BUSINESS,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1MbUS2EdQRMVd2yEV2yuWyFR: {
    subscriptionType: SubscriptionType.LIMITED,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1MbV1DEdQRMVd2yEgDAXgYTc: {
    subscriptionType: SubscriptionType.LIMITED,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1MbUU9EdQRMVd2yEAzC7UYfW: {
    subscriptionType: SubscriptionType.PROFESSIONAL,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1MbV3EEdQRMVd2yEHERfSfGC: {
    subscriptionType: SubscriptionType.PROFESSIONAL,
    subscriptionLength: SubscriptionLength.YEARLY,
  },

  // price_1OgzQIEdQRMVd2yEVWIkxJtp: {
  //   subscriptionType: SubscriptionType.SOLO,
  //   subscriptionLength: SubscriptionLength.MONTHLY,
  // },
  // price_1OgzQIEdQRMVd2yE4QKDX5Rl: {
  //   subscriptionType: SubscriptionType.SOLO,
  //   subscriptionLength: SubscriptionLength.YEARLY,
  // },
  // price_1OgzRNEdQRMVd2yEHTP4bFfx: {
  //   subscriptionType: SubscriptionType.TEAM,
  //   subscriptionLength: SubscriptionLength.MONTHLY,
  // },
  // price_1OgzS3EdQRMVd2yESU4L3bHb: {
  //   subscriptionType: SubscriptionType.TEAM,
  //   subscriptionLength: SubscriptionLength.YEARLY,
  // },

  price_1OlU3NEdQRMVd2yET1mdz5n7: {
    subscriptionType: SubscriptionType.SOLO,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1OlU3NEdQRMVd2yE5Pp7p38m: {
    subscriptionType: SubscriptionType.SOLO,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
  price_1OlU71EdQRMVd2yEQoxUb1VD: {
    subscriptionType: SubscriptionType.TEAM,
    subscriptionLength: SubscriptionLength.MONTHLY,
  },
  price_1OlU7VEdQRMVd2yEH8zKJuu6: {
    subscriptionType: SubscriptionType.TEAM,
    subscriptionLength: SubscriptionLength.YEARLY,
  },
};

export const StripeSubscriptionPriceIds: SubscriptionPriceIds =
  process.env.NODE_ENV === 'development'
    ? devSubscriptionIds
    : prodSubcriptionIds;

export const StripeSubscriptionPriceIdsLookup: SubscriptionPriceIdsLookup =
  process.env.NODE_ENV === 'development'
    ? devSubscriptionIdsLookup
    : prodSubscriptionIdsLookup;

export interface UserStripeSubscriptionData {
  subscription: string;
  subscriptionType: SubscriptionType;
  subscriptionLength: SubscriptionLength;
  isAdmin: boolean;
  customerID?: string;
  subItemID?: string;
  mainSubID?: string;
  onTrial?: boolean;
}
