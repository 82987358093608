import { FC } from 'react';

type FacebookSvgProps = {
  stroke?: string;
};

export const FacebookSvg: FC<FacebookSvgProps> = ({
  stroke = 'var(--ea-white)',
}) => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_224_3985)">
<path d="M14 7.04278C14 3.15316 10.866 0 7 0C3.134 0 0 3.15316 0 7.04278C0 10.558 2.55979 13.4717 5.90625 14V9.07858H4.12891V7.04278H5.90625V5.49117C5.90625 3.72607 6.95133 2.75109 8.55025 2.75109C9.31588 2.75109 10.1172 2.88864 10.1172 2.88864V4.62182H9.23453C8.365 4.62182 8.09375 5.16475 8.09375 5.72226V7.04278H10.0352L9.7248 9.07858H8.09375V14C11.4402 13.4717 14 10.558 14 7.04278Z" fill={stroke}/>
</g>
<defs>
<clipPath id="clip0_224_3985">
<rect width="14" height="14" fill={stroke}/>
</clipPath>
</defs>
</svg>

  );
};
